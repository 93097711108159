import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service"
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  requestData: any;
  notifier: any;
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }
  ngOnInit() {

    this.loginForm = this.formBuilder.group({

      // username: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])]],
      password: ['', [Validators.required, Validators.minLength(4)]],

    });
    this.generateKeys()

  }

  get f() { return this.loginForm.controls; }

  login() {

    this.submitted = true;
    // stop here if form is invalid 
    if (this.loginForm.invalid) {
      return;
    } else {

     

      let requestData = {
        "email": this.loginForm.controls.email.value,
        "password": this.loginForm.controls.password.value

      }

      if(!this.ValidateEmail(requestData.email)) return this.notifier.notify('error', 'Please enter valid email')
      this.api.login(requestData).subscribe(
        (res) => {
          if (res['status'] && res['code'] == 200) {
            localStorage.setItem('token', res['token'])
            localStorage.setItem('userId',res['results']['email'])
            localStorage.setItem('profile',res['results']['profile'])
            localStorage.setItem('name',res['results']['fullName'])
            
            this.notifier.notify('success', res['message'])
            this.router.navigate(['/admin/dashboard'])
          } else {
            this.notifier.notify('error', res['message'])
          }
        },
        (err) => {
          if (err instanceof HttpErrorResponse) {
            this.notifier.notify('error', 'Server side error')
          }else{
            this.notifier.notify('error', 'Client side error')

          }
        }
      );
    }
  }

  generateKeys(){
    this.api.generateKeys().subscribe(response=>{
      localStorage.setItem('public_key',response['public_key'])
      console.log(response)
    })
  }

   ValidateEmail(inputText) {
    var mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;; 
    if (inputText.match(mailformat)) {
      return true;
    }
    else {
      return false;
    }
  }
}
