import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;


@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrls: ['./report-management.component.css']
})
export class ReportManagementComponent implements OnInit {

  title = "Report Management"
  private readonly notifier: NotifierService;
  responseData: any;
  public IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
  public search = '';
  public length:any;
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  users: any;
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,


  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.getReportUserList()
  }

  getReportUserList(){
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    this.SpinnerService.show()
      this.api.reportUserList().subscribe(data=>{
        if(data['status'] && data['code']==200){
          this.responseData=data['data'];
          this.users = data['users']
          this.length = this.responseData.length
          console.log(this.responseData,this.users);
          this.SpinnerService.hide()
          
        }else{
          this.SpinnerService.hide()
          this.notifier.notify('error', data['message'])
        }
      },(err)=>{
        this.SpinnerService.hide()
        if(err instanceof HttpErrorResponse){
          this.notifier.notify('error', `Error Status ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')
        }

      })
  }

 

  pageChanged(val) {
    this.p=val
    console.log(val,"val==")
    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getReportUserList()

  }
  blockUser(userId) {
    var email = localStorage.getItem('user')
    Swal.fire({
      title: 'Are you sure, You want to block this user ?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, block it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      console.log(result,"ress==")
      if (result.value) {

        this.api.blockUser({ user_id: userId ,email:email}).subscribe(
          (res) => {
            if (res && res['code'] == 200) {
              Swal.fire(
                'Blocked!',
                'User has been blocked.',
                'success'
              )
              this.getReportUserList()


            } else {

              Swal.fire(
                'Something Went Wrong.',
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {
            if(err instanceof HttpErrorResponse){
              Swal.fire(
                `Error ${err.status} ${err.message}`,
                'Please try Again after some time.',
                'error'
              )
            }else{
              Swal.fire(
                'Client side error',
                'Please try Again after some time.',
                'error'
              )
            }
            
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }
  unblockUser(userId){
    var email = localStorage.getItem('user')
    
    Swal.fire({
      title: 'Are you sure, You want to unblock this user ?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, unblock it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      console.log(result,"ress==")
      if (result.value) {

        this.api.unblockUser({ user_id: userId,email:email }).subscribe(
          (res) => {
            if (res && res['code'] == 200) {
              Swal.fire(
                'UnBlocked!',
                'User has been unblocked.',
                'success'
              )
              this.getReportUserList()


            } else {

              Swal.fire(
                'Something Went Wrong.',
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {
            if(err instanceof HttpErrorResponse){
              Swal.fire(
                `Error ${err.status} ${err.message}`,
                'Please try Again after some time.',
                'error'
              )
            }else{
              Swal.fire(
                'Client side error',
                'Please try Again after some time.',
                'error'
              )
            }
            
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }
  deleteUser(userId){
   var email = localStorage.getItem('user')
    Swal.fire({
      title: 'Are you sure, You want to delete this user ?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      console.log(result,"ress==")
      if (result.value) {

        this.api.deleteReportedUser({id:userId}).subscribe(
          (res) => {
            if (res && res['code'] == 200) {
              Swal.fire(
                'Deleted!',
                'User has been deleted.',
                'success'
              )
              this.getReportUserList()


            } else {

              Swal.fire(
                `${res['message']}`,
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {
            if(err instanceof HttpErrorResponse){
              Swal.fire(
                `Error ${err.status} ${err.message}`,
                'Please try Again after some time.',
                'error'
              )
            }else{
              Swal.fire(
                'Client side error',
                'Please try Again after some time.',
                'error'
              )
            }
            
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }
}
