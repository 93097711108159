import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit {
  private readonly notifier: NotifierService;
  public title = 'Update Profile';
  public submitted = false;
  fileToUpload: File = null;
  public fileUrl: string = '';
  email: any = {};
  myForm: FormGroup;
  public IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
  profile:any;
  name:any;
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public api: ApiCallingService,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService
  }
  ngOnInit() {

    this.email = localStorage.getItem('userId');
    this.profile =localStorage.getItem('profile');
    this.name =localStorage.getItem('name');
    
    this.myForm = this.formBuilder.group({
      password: [''],
      new_password: [''],
      avatar: [''],
      email: [this.email, [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      name: [this.name],
    })

  }

  submit() {
    let passValid = true;
    this.submitted = true;
    console.log(this.myForm.valid)
    if (this.myForm.valid) {
      var formData = new FormData();
      formData.append('email', this.myForm.value.email)
      formData.append('fullName', this.myForm.value.name)
      // formData.append('file', this.myForm.value.avatar == null ? '' : this.myForm.value.avatar)
      formData.append('currentPassword', this.myForm.controls.password.value)
      formData.append('password', this.myForm.controls.new_password.value ? this.myForm.controls.new_password.value : '')
      if (this.myForm.value.password) {
        if (this.myForm.value.new_password) {

        } else {
          passValid = false
          alert('Please enter new password.')

        }
      }
      if (this.myForm.value.new_password) {
        if (this.myForm.value.password) {

        } else {
          passValid = false
          alert('Please enter current password.')
        }
      }
      console.log(passValid)
      if (passValid) {
        this.api.updateProfile(formData).subscribe(
          (res) => {
            this.submitted = false;
            if (res['status'] && res['code'] == 200) {
              // this.responseData = res['results']
              this.notifier.notify('success', res['message'])
              localStorage.setItem('name',this.myForm.value.name)
              window.location.reload();
            } else {
              this.notifier.notify('error', res['message'])
            }
          },
          (err) => {
            console.log(err)
            if (err instanceof HttpErrorResponse) {
              this.notifier.notify('error', `Error ${err.message} Found`)
            }else{
              this.notifier.notify('error', 'Client side error')
    
            }
          }
        );
      } else {
        console.log("Enter valid details.")
      }

    }
  }

  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.myForm.patchValue({
      avatar: file
    });
    this.myForm.get('avatar').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    if (file.type.indexOf('image') > -1) {
      // this.format = 'image';
    } else if (file.type.indexOf('video') > -1) {
      // this.format = 'video';
      this.myForm.patchValue({
        isvideo: true
      });
      this.myForm.get('isvideo').updateValueAndValidity()

    }
    reader.onload = () => {
      this.fileUrl = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  resetForm() {
    this.submitted = false;
    this.myForm.reset();
    this.myForm.controls.avatar.setValue(null)
    this.fileUrl = '';
    this.myForm.controls.fluentID.setValue('');
  }
}
