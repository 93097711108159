<div class="row">
  <div class="col-md-12">
    <h3 class="page-title"> {{title}} </h3>
    <ul class="page-breadcrumb breadcrumb">
      <li class="btn-group" routerLink="/admin/job_management"> <a class="btn green dropdown-toggle "> <i
            class="fa fa-backward" aria-hidden="true"></i> <span> Back
          </span></a> </li>
      <li> <i class="fa fa-home"></i> <a> Home </a> <i class="fa fa-angle-right"></i>
      </li>
      <li> <a href="javascript:void(0);"> {{title}} </a> </li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <form>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Image</label>
        <div class="col-md-9">
          <img class="imgDisp" width="200" height="200"
            [src]="responseData.file == undefined || responseData.file == ''? IMAGE_BASE_URL+'/images/' +responseData.consumer._id+ '/default_user.png': IMAGE_BASE_URL+'/images/' +responseData.consumer._id+ '/'+responseData.file">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Consumer</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control"
            [value]="responseData.consumer && responseData.consumer.username">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Artist</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control"
            [value]="responseData.artist && responseData.artist.username ">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Title</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.title">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Description</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.description">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Location</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.location_name">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Price</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.price">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Status</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.status">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Rating</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.rating">
        </div>
      </div>
    </form>
  </div>
</div>