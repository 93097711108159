import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public title = 'Change Password';
  public submitted = false;
  myForm: FormGroup;
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private router : Router
    ) {
      this.notifier = notifierService;
    }
  ngOnInit() {
    this.myForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],

    })
  }

  submit() {
    this.submitted = true;
    // if (this.myForm.valid) {
    //   let api_url = 'admin/change_password';

    //   this.api.changePassword({
    //     old_password: this.myForm.controls.password.value,
    //     new_password: this.myForm.controls.new_password.value ? this.myForm.controls.new_password.value : ''
    //   }).subscribe(
    //     (res) => {
    //       this.submitted = false;
    //       this.myForm.reset();
    //       if (res['code'] == 200) {
    //         this.notifier.notify('success', `Success: Password changed successfully.`);
    //       } else {
    //         this.notifier.notify('error', `Error: ${res.message}.`);
    //       }
    //     },
    //     (err) => {
    //     }
    //   );
    // }
    if(this.myForm.controls.password.value){
      if(this.myForm.controls.new_password.value == '') return alert('New password field cannot be empty')
      Swal.fire({
        title: 'Are you sure, You want to change password ?',
        
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        console.log(result,"ress==")
        if (result.value) {
  
          this.api.changePassword({
                email:localStorage.getItem('userId'),
                oldPassword: this.myForm.controls.password.value,
                newPassword: this.myForm.controls.new_password.value ? this.myForm.controls.new_password.value : ''
              }).subscribe(
            (res) => {
              if (res && res['code'] == 200) {
                // this.myForm.controls.password['value']=''
                this.router.navigate(['/admin/dashboard'])
                Swal.fire(
                  'Changed!',
                  'Password has been changed.',
                  'success'
                )
                  
              } else {
  
                Swal.fire(
                  `${res['message']}`,
                  'Please try Again after some time.',
                  'error'
                )
              }
  
            },
            (err) => {
              if(err instanceof HttpErrorResponse){
                Swal.fire(
                  `Error ${err.status} ${err.message}`,
                  'Please try Again after some time.',
                  'error'
                )
              }else{
                Swal.fire(
                  'Client side error',
                  'Please try Again after some time.',
                  'error'
                )
              }
              
            }
          );
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
  
          Swal.fire(
            'Cancelled',
            'Your request has been cancelled',
            'error'
          )
        }
      })
    }else{
      return alert('Password field cannot be empty')
    }
   
  }
}
