import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { NotifierService } from "angular-notifier";
@Component({
  selector: 'app-reel-detail',
  templateUrl: './reel-detail.component.html',
  styleUrls: ['./reel-detail.component.css']
})
export class ReelDetailComponent implements OnInit {
  title = "Reel Management"
  public responseData: any = [];
  public IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
  public search = '';
  public length:any;
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  public totalCount:any;
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,

  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let email = localStorage.getItem('user')
    // this.responseData = []
    // this.length = this.responseData.length
    this.SpinnerService.show();
    this.api.reel_List({skip : 0, limit : 10000}).subscribe(
      (res) => {
        console.log(res,"Spam Data I have")

        if (res['status'] && res['code'] == 200) {
          console.log(res , "pareka pareka")
          this.responseData = res['changedata']
          console.log(JSON.stringify(this.responseData),"Eureka Eureka")
          this.length = this.responseData.length
          this.totalCount = res['count'];
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();

          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        console.log(err.message," this is my message")
          this.SpinnerService.hide();
        
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );

  }

}
