import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})

export class UserManagementComponent implements OnInit {
  public title = 'User Management';
  public responseData: any = [];
  public IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
  public search = '';
  public length:any;
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  public totalCount:any;
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,

  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    console.log(this.IMAGE_BASE_URL,"jsdhjk")
    this.getData();
    this.searchUser()
  }

  getData() {
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let email = localStorage.getItem('user')
    // this.responseData = []
    // this.length = this.responseData.length
    this.SpinnerService.show();
    this.api.getUsers({email:email,page:this.p,itemsPerPage:this.itemsPerPage}).subscribe(
      (res) => {

        if (res['status'] && res['code'] == 200) {
          
          this.responseData = res['data']
          console.log(this.responseData,"data")
          this.length = this.responseData.length
          this.totalCount = res['count'];
          console.log(this.totalCount)
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();

          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
          this.SpinnerService.hide();
        
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );

  }

  searchUser(){

    this.api.searchUser({searchItem:this.search}).subscribe(res=>{
      if (res['status'] && res['code'] == 200) {
        
        this.responseData = res['data']
        console.log(this.responseData,"data")
        this.length = this.responseData.length
        this.totalCount = res['count'];
        console.log(this.totalCount)
      } else {
        this.notifier.notify('error', res['message'])
      }

    },err=>{
      if (err instanceof HttpErrorResponse) {
        this.notifier.notify('error', `Error ${err.status} Found`)
      }else{
        this.notifier.notify('error', 'Client side error')
      }
    })
  }

  // activeInactiveUser(val, status) {

  //   Swal.fire({
  //     text: status ? 'Are you sure, You want to inactive this user ?' : 'Are you sure, You want to active this user?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Submit',
  //     cancelButtonText: 'Cancel'
  //   }).then((result) => {

  //     if (result.value) {

  //       this.api.postRequest('admin/blockUnBlockUser', {
  //         user_id: val,
  //         block: status ? true : false
  //       }).then(
  //         (res) => {
  //           if (res && res.code == 200) {
  //             Swal.fire(
  //               'Success!',
  //               status ? 'User marked inactive successfully.' : 'User marked active successfully.',
  //               'success'
  //             )

  //             for (var i in this.responseData) {
  //               if (this.responseData[i]._id.toString() == val.toString()) {
  //                 this.responseData[i].isBlocked = status;
  //               }
  //             }

  //           } else {
  //             Swal.fire(
  //               'Something Went Wrong.',
  //               'Please try again after some time.',
  //               'error'
  //             )
  //           }
  //         },
  //         (err) => {
  //           Swal.fire(
  //             'Something Went Wrong.',
  //             'Please try again after some time.',
  //             'error'
  //           )
  //         }
  //       );
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire(
  //         'Cancelled',
  //         'Your request has been cancelled',
  //         'error'
  //       )
  //     }
  //   })
  // }

  pageChanged(val) {
    this.p=val
    console.log(val,"val==")
    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getData()

  }

  blockUser(userId) {
    var email = localStorage.getItem('user')
    Swal.fire({
      title: 'Are you sure, You want to block this user ?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, block it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      console.log(result,"ress==")
      if (result.value) {

        this.api.blockUser({ user_id: userId ,email:email}).subscribe(
          (res) => {
            if (res && res['code'] == 200) {
              Swal.fire(
                'Blocked!',
                'User has been blocked.',
                'success'
              )
              this.getData()


            } else {

              Swal.fire(
                'Something Went Wrong.',
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {
            if(err instanceof HttpErrorResponse){
              Swal.fire(
                `Error ${err.status} ${err.message}`,
                'Please try Again after some time.',
                'error'
              )
            }else{
              Swal.fire(
                'Client side error',
                'Please try Again after some time.',
                'error'
              )
            }
            
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }
  unblockUser(userId){
    var email = localStorage.getItem('user')
    
    Swal.fire({
      title: 'Are you sure, You want to unblock this user ?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, unblock it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      console.log(result,"ress==")
      if (result.value) {

        this.api.unblockUser({ user_id: userId,email:email }).subscribe(
          (res) => {
            if (res && res['code'] == 200) {
              Swal.fire(
                'UnBlocked!',
                'User has been unblocked.',
                'success'
              )
              this.getData()


            } else {

              Swal.fire(
                'Something Went Wrong.',
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {
            if(err instanceof HttpErrorResponse){
              Swal.fire(
                `Error ${err.status} ${err.message}`,
                'Please try Again after some time.',
                'error'
              )
            }else{
              Swal.fire(
                'Client side error',
                'Please try Again after some time.',
                'error'
              )
            }
            
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }
  deleteUser(userId){
   var email = localStorage.getItem('user')
    Swal.fire({
      title: 'Are you sure, You want to delete this user ?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      console.log(result,"ress==")
      if (result.value) {

        this.api.deleteUser({id:userId,email:email}).subscribe(
          (res) => {
            if (res && res['code'] == 200) {
              Swal.fire(
                'Deleted!',
                'User has been deleted.',
                'success'
              )
              this.getData()


            } else {

              Swal.fire(
                `${res['message']}`,
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {
            if(err instanceof HttpErrorResponse){
              Swal.fire(
                `Error ${err.status} ${err.message}`,
                'Please try Again after some time.',
                'error'
              )
            }else{
              Swal.fire(
                'Client side error',
                'Please try Again after some time.',
                'error'
              )
            }
            
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }
}
