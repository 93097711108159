import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  public title = 'User Detail';
  public responseData: any = [];
  public id: string = '';
  public IMAGE_BASE_URL;
  public isVerified:any;
  public ProfileLength:any;
  public screens1:any;
  public screens2:any;
  public screens3:any;
  public screens4:any;

  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getData()
    })
    this.IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
  }

  getData() {

    // stop here if form is invalid 

    let api_url = 'admin/user_detail?';
    api_url = api_url + '_id=' + this.id;
    let email =localStorage.getItem('user')
    this.api.user_detail({id:this.id,email:email}).subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          this.responseData = res['results']
          this.screens1 = this.responseData.screens.length?this.responseData.screens[0]:[];
          this.screens2 = this.responseData.screens[1]?this.responseData.screens[1].images:[]
          this.screens3 = this.responseData.screens[2]?this.responseData.screens[2]:[]
          this.screens4 = this.responseData.screens[3]?this.responseData.screens[3].ocr_docs:[];

          console.log(this.screens1,"--------")
          this.isVerified = this.responseData.screens[3]?this.responseData.screens[3].ocr_docs:[].length;
          this.ProfileLength = this.responseData.screens[1]?this.responseData.screens[1].images:[].length;

          console.log( this.isVerified)
          
        } else {
          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );

  }
}
