<div class="header navbar navbar-fixed-top">
  <div class="header-inner">
    <a class="navbar-brand" style="width: auto; ">
     Professions Entwined
    </a>
    <a href="javascript:;" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
      <img src="assets/img/menu-toggler.png" alt="" />
    </a>
    <ul class="nav navbar-nav pull-right">
      <!-- BEGIN USER LOGIN DROPDOWN -->
      <li class="dropdown user">
        <a class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
          <!-- <img alt="" src="assets/img/avatar1_small.jpg" /> -->
          <span class="username">
            {{name}}
          </span>
          <i class="fa fa-angle-down"></i>
        </a>
        <ul class="dropdown-menu">
          <!-- <li>
            <a href="extra_profile.html">
              <i class="fa fa-user"></i> My Profile
            </a>
          </li> -->
          <li routerLink="/admin/update_profile">
            <a>
              <i class="fa fa-key"></i> Update Profile
            </a>
          </li>
          <li routerLink="/admin/change-password">
            <a>
              <i class="fa fa-refresh"></i> Change Password
            </a>
          </li>
          <li>
            <a (click)="logout()">
              <i class="fa fa-sign-out"></i> Log Out
            </a>
          </li>
        </ul>
      </li>
      <!-- END USER LOGIN DROPDOWN -->
    </ul>
    <!-- END TOP NAVIGATION MENU -->
  </div>
  <!-- END TOP NAVIGATION BAR -->
</div>
<!-- END HEADER -->
<div class="clearfix">
</div>