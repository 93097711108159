<div class="container-fluid">
  <div style="float: right; cursor:pointer;">
    <!-- <i class="fa fa-backward" aria-hidden="true" routerLink="/admin/feeds-management"></i> -->
    <a class="btn green " routerLink="/admin/feeds-management"> <i
      class="fa fa-backward" aria-hidden="true"></i> <span> Back
    </span></a>
   </div>
</div>
<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleCaptions"  *ngFor="let item of responseData;let index = index" [attr.data-slide-to]="index"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let item of responseData;let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
        <img [src]="IMAGE_BASE_URL+userid+'/'+item" class="d-block w-100" style="height: 200px; width: 150px;">
        <div class="carousel-caption d-none d-md-block">
          <!-- <h5 [textContent]="item.title"></h5>
          <p [textContent]="item.symmery"></p> -->
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>