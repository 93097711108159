<!-- BEGIN SIDEBAR -->
<div id = "sidebar_style" class="page-sidebar-wrapper">
  <div class="page-sidebar navbar-collapse collapse">
    <!-- add "navbar-no-scroll" class to disable the scrolling of the sidebar menu -->
    <!-- BEGIN SIDEBAR MENU -->
    <ul class="page-sidebar-menu" data-auto-scroll="true" data-slide-speed="200">


      <li class="start" routerLink="/admin/dashboard" routerLinkActive="active">
        <a>
          <i class="fa fa-home"></i>
          <span class="title">
            Dashboard
          </span>
        </a>
      </li>
      <li routerLink="/admin/user_management" routerLinkActive="active">
        <a>
          <i class="fa fa-user"></i>
          <span class="title">
            Users Management
          </span>

        </a>
      </li>
      <li routerLink="/admin/feeds-management" routerLinkActive="active">
        <a>
          <i class="fa fa-rss" aria-hidden="true"></i>
          <span class="title">
            Feeds Management
          </span>

        </a>
      </li>
      <li routerLink="/admin/reports-management" routerLinkActive="active">
        <a>
          <i class="fa fa-file" aria-hidden="true"></i>
          <span class="title">
            Reports Management
          </span>

        </a>
      </li>
    <!-- </li> -->
    <!-- <li routerLink="/admin/reel-management" routerLinkActive="active">
      <a>
        <i class="fa fa-play" aria-hidden="true"></i>
        <span class="title">
          Reel Management
        </span>

      </a>
    </li> -->
      
      <li routerLink="/admin/spam-management" routerLinkActive="active">
        <a>
          <i class="fa fa-bug" aria-hidden="true"></i>
          <span class="title">
            Spam Management
          </span>

        </a>
      </li>
      <li routerLink="/admin/category-management" routerLinkActive="active">
        <a>
          <i class="fa fa-list-ul"></i>
          <span class="title">
            Category Management
          </span>

        </a>
      </li>
      <li routerLink="/admin/advice-management" routerLinkActive="active">
        <a>
          <i class="fa fa-bullhorn" aria-hidden="true"></i>
          <span class="title">
            Advice Management
          </span>
          <span class="selected">
          </span>
        </a>
      </li>
      <li routerLink="/admin/hobbies-management" routerLinkActive="active">
        <a>
          <i class="fa fa-magic" aria-hidden="true"></i>
          <span class="title">
            Hobbies Management
          </span>
          <span class="selected">
          </span>
        </a>
      </li>
      <li routerLink="/admin/proffesion-management" routerLinkActive="active">
        <a>
          <i class="fa fa-users" aria-hidden="true"></i>
          <span class="title">
            Profession Management
          </span>
          <span class="selected">
          </span>
        </a>
      </li>
      <li routerLink="/admin/questions-management" routerLinkActive="active">
        <a>
          <i class="fa fa-question" aria-hidden="true"></i>
          <span class="title">
            Questions Management
          </span>
          <span class="selected">
          </span>
        </a>
      </li>
      <li routerLink="/admin/outfit_management" routerLinkActive="active">
        <a>
          <i class="fa fa-female" aria-hidden="true"></i>
          <span class="title">
            Outfit Management
          </span>
          <span class="selected">
          </span>
        </a>
      </li>
      <li routerLink="/admin/job_management" routerLinkActive="active">
        <a>
          <i class="fa fa-briefcase" aria-hidden="true"></i>
          <span class="title">
            Jobs Management
          </span>
          <span class="selected">
          </span>
        </a>
      </li>
      <li routerLink="/admin/nanny_management" routerLinkActive="active">
        <a>
          <i class="fa fa-female" aria-hidden="true"></i>
          <span class="title">
            Nanny Management
          </span>
          <span class="selected">
          </span>
        </a>
      </li>
      <li routerLink="/admin/subscription_management" routerLinkActive="active">
        <a>
          <i class="fa fa-rocket" aria-hidden="true"></i>
          <span class="title">
            Subscription Management
          </span>
          <span class="selected">
          </span>
        </a>
      </li>
      <!-- <li routerLink="/admin/generate-promo-code" routerLinkActive="active">
        <a>
          <i class="fa fa-file-text-o" aria-hidden="true"></i>
          <span class="title">
            Generate Promo code
          </span>
        </a>
      </li> -->
      <!-- <li routerLink="/admin/post_management" routerLinkActive="active">
        <a>
          <i class="fa fa-clipboard" aria-hidden="true"></i>
          <span class="title">
             Posts Management
          </span>
        </a>
      </li> -->
      <!-- <li routerLink="/admin/reported_post_management" routerLinkActive="active">
        <a>
          <i class="fa fa-clipboard" aria-hidden="true"></i>
          <span class="title">
            Reported Posts
          </span>
        </a>
      </li> -->
     
      <li class="" id="lichange" (click)="changeDropdown()">
        <a>
          <i class="fa fa-shopping-cart"></i>
          <span class="title">
            Content Management
          </span>
          <span class="selected">
          </span>
          <span class="arrow open">
          </span>
        </a>
        <ul class="sub-menu" id="cont">
          <li routerLink="/admin/terms_and_condition" routerLinkActive="active">
            <a>
              <i class="fa fa-file-text-o" aria-hidden="true"></i>
              <span class="title">
                 Terms & Conditions
              </span>
            </a>
          </li>

          <li routerLink="/admin/privacy_policy" routerLinkActive="active">
            <a>
              <i class="fa fa-lock" aria-hidden="true"></i>
              <span class="title">
                 Privacy Policy
              </span>
            </a>
          </li>

       

          <!--<li routerLink="/admin/disclaimer" routerLinkActive="active">
            <a>
              <i class="fa fa-file-text-o" aria-hidden="true"></i>
              <span class="title">
                Disclaimer
              </span>
            </a>
          </li> -->
        </ul>
      </li>
    </ul>
    <!-- END SIDEBAR MENU -->
  </div>
</div>

<!-- END SIDEBAR -->