import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NotifierService } from "angular-notifier";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.css']
})
export class ContentManagementComponent implements OnInit {
  public Editor = ClassicEditor;
  public submitted: false;
  content: string;
  type: number;
  htmlContent = '';
  title = '';
  responseData:any;
  email:any;
  private readonly notifier: NotifierService;


  constructor(
    private router: Router, 
    public api: ApiCallingService,
    notifierService: NotifierService

    ) {
      this.notifier = notifierService;
    }
  ngOnInit() {
    this.email = localStorage.getItem('user')

    this.getData();
    // if (window.location.hash == '#/admin/cancellation-policy') {
    //   this.type = 5
    //   this.title = 'Cancellation Policy'
    //   // this.getData()
    // } else if (window.location.hash == '#/admin/privacy_policy') {
    //   this.type = 2
    //   this.title = 'Privacy Policy'
    //   // this.getData()
    // } else if (window.location.hash == '#/admin/about_us') {
    //   this.type = 3
    //   this.title = 'About Us'
    //   // this.getData()
    // } else if (window.location.hash == '#/admin/terms_and_condition') {
    //   this.type = 1
    //   this.title = 'Terms & Condition'
    //   // this.getData()
    // } else if (window.location.hash == '#/admin/artist_terms_and_condition') {
    //   this.type = 7
    //   this.title = 'Terms & Condition'
    //   // this.getData()
    // }else if (window.location.hash == '#/admin/artist_privacy_policy') {
    //   this.type = 8
    //   this.title = 'Terms & Condition'
    //   // this.getData()
    // }else if (window.location.hash == '#/admin/disclaimer') {
    //   this.type = 4
    //   this.title = 'Disclaimer'
    //   // this.getData()
    // }
    // else if (window.location.hash == '#/support') {
    //   this.type = 6
    //   this.title = 'support'
    //   // this.getData()
    // }
    // else {
    //   console.log("No type")
    // }
  }

  getData() {
    var apiCall;
    if(window.location.hash == '#/admin/privacy_policy') apiCall = this.api.get_privacy_policy({email:this.email})
    if(window.location.hash == '#/admin/terms_and_condition') apiCall = this.api.get_terms_condtion({email:this.email})
    apiCall.subscribe(
      (res) => {
        if (res['status']) {
          this.htmlContent = res['message']
          
        } else {
          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );
  }

  submit() {
    var apiCall;
   if(window.location.hash == '#/admin/privacy_policy') apiCall = this.api.privacy_policy({data:this.htmlContent,email:this.email})
   if(window.location.hash == '#/admin/terms_and_condition') apiCall = this.api.terms_condtion({data:this.htmlContent,email:this.email})
   
   apiCall.subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          this.responseData = res['results']
          this.notifier.notify('success', res['message'])
          console.log( this.responseData)
          
        } else {
          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );
  }
}

