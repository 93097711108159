import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import {
  CanActivateRouteGuard as AuthGuard
} from './configs/can-activate-route.guard';
// modules
import { LoginComponent } from './view/login/login.component';
import { ForgotPasswordComponent } from './view/forgot-password/forgot-password.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { MainComponent } from './view/main/main.component';
import { UserManagementComponent } from './view/user-management/user-management.component';
import { UserDetailComponent } from './view/user-detail/user-detail.component';
import { ChangePasswordComponent } from './view/change-password/change-password.component';
import { ContentManagementComponent } from './view/content-management/content-management.component';
import { PagenotfoundComponent } from './view/pagenotfound/pagenotfound.component';
import { JobManagementComponent } from './view/job-management/job-management.component';
import { ViewCategoryComponent } from './view/view-category/view-category.component';
import { UpdateProfileComponent } from './view/update-profile/update-profile.component';
import { SubscriptionComponent } from './view/subscription/subscription.component';
import { FeedsManagementComponent } from './view/feeds-management/feeds-management.component';
import { FeedDetailComponent } from './view/feed-detail/feed-detail.component';
import { ReportManagementComponent } from './view/report-management/report-management.component';
import { AdviceManagementComponent } from './view/advice-management/advice-management.component';
import { CategoryManagementComponent } from './view/category-management/category-management.component';
import { HobbiesManagementComponent } from './view/hobbies-management/hobbies-management.component';
import { ProffessionManagementComponent } from './view/proffession-management/proffession-management.component';
import { OutfitManagementComponent } from './view/outfit-management/outfit-management.component';
import { NanyManagementComponent } from './view/nany-management/nany-management.component';
import { QuestionsManagementComponent } from './view/questions-management/questions-management.component';
import { SpamManagementComponent } from './view/spam-management/spam-management.component';
const routes: Routes = [
  {
    path: 'login', component: LoginComponent

  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: 'admin', component: MainComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'user_management', component: UserManagementComponent, canActivate: [AuthGuard] },
      { path: 'feeds-management', component: FeedsManagementComponent, canActivate: [AuthGuard] },
      { path : 'advice-management', component: AdviceManagementComponent, canActivate: [AuthGuard]},
      { path: 'reports-management', component: ReportManagementComponent, canActivate: [AuthGuard] },

      { path: 'subscription_management', component: SubscriptionComponent, canActivate: [AuthGuard] },
      { path: 'user_management/user_detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },

      { path: 'feeds-management/feed-detail/:id', component: FeedDetailComponent, canActivate: [AuthGuard] },
      { path: 'reports-management/user_detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
      { path: 'category-management', component: CategoryManagementComponent, canActivate: [AuthGuard] },
      { path: 'outfit_management', component: OutfitManagementComponent, canActivate: [AuthGuard] },
      { path: 'nanny_management', component: NanyManagementComponent, canActivate: [AuthGuard] },
      { path: 'category_management/category_detail/:id', component: ViewCategoryComponent, canActivate: [AuthGuard] },
      { path: 'job_management', component: JobManagementComponent, canActivate: [AuthGuard] },
      { path: 'update_profile', component: UpdateProfileComponent, canActivate: [AuthGuard] },
      { path: 'terms_and_condition', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'artist_terms_and_condition', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'privacy_policy', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'artist_privacy_policy', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'disclaimer', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'about_us', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      { path: 'hobbies-management', component: HobbiesManagementComponent, canActivate: [AuthGuard] },
      { path: 'proffesion-management', component: ProffessionManagementComponent, canActivate: [AuthGuard] },
      { path: 'questions-management', component: QuestionsManagementComponent, canActivate: [AuthGuard] },
      {path : 'spam-management' , component : SpamManagementComponent, canActivate : [AuthGuard]},

      { path: '', redirectTo: '/admin/dashboard', pathMatch: 'full' },
    ]
  },

  {
    path: '',
    redirectTo: '/admin/dashboard',
    pathMatch: 'full'
  },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
