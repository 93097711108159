import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public title = 'Dashboard';
  public responseData: any = {};
  private readonly notifier: NotifierService;
  public totalFeeds:any
  public totalReportedUser:any
  public
  constructor(
    private http: HttpClient,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService
   }

  ngOnInit() {
    this.getData();
    this.getFeeds();
    this.getReportUserList()
  }

  getData() {
    let email = localStorage.getItem('user')
    this.api.getUsers({email:email}).subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          console.log(res['data'])
          this.responseData = res['data'].length
          // this.notifier.notify('success', res['message'])
        } 
        // else {
        //   this.notifier.notify('error', res['message'])
        // }
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );

  }

  getFeeds() {
    // if (this.search) {
    //   this.p = 1;
    //   this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    // }
    // let email = localStorage.getItem('user')

    this.api.getFeeds({}).subscribe(
      (res) => {

        if (res['status'] && res['code'] == 200) {
    
          this.totalFeeds = res['count']
        } 
        // else {
        //   this.notifier.notify('error', res['message'])
        // }
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );

  }

   getReportUserList(){
   
      this.api.reportUserList().subscribe(data=>{
        if(data['status'] && data['code']==200){
          this.responseData=data['data'];
          // this.users = data['users']
          this.totalReportedUser = this.responseData.length
          // console.log(this.responseData,this.users);
        }
      },(err)=>{
        if(err instanceof HttpErrorResponse){
          this.notifier.notify('error', `Error Status ${err.status} Found`)
        }
        else{
          this.notifier.notify('error', 'Client side error')
        }

      })
  }

}
