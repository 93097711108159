<div class="row">
    <div class="col-md-12">
        <h3 class="page-title"> {{title}} </h3>
        <ul class="page-breadcrumb breadcrumb">
            <li> <i class="fa fa-home"></i>
                <a href="#"> Home </a> <i class="fa fa-angle-right"></i>
            </li>
            <li> <a href="javascript:void(0);"> {{title}} </a> </li>
        </ul>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat blue">
                    <div class="visual">
                        <i class="fa fa-user"></i>
                    </div>
                    <div class="details">
                        <div class="number">
                            {{responseData}}
                        </div>
                        <div class="desc">
                            Users
                        </div>
                    </div>
                    <a class="more" routerLink="/admin/user_management">
                        View more <i class="m-icon-swapright m-icon-white"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat green">
                    <div class="visual">
                        <i class="fa fa-rss" aria-hidden="true"></i>
                    </div>
                    <div class="details">
                        <div class="number">
                            {{totalFeeds}}
                        </div>
                        <div class="desc">
                            Feeds
                        </div>
                    </div>
                    <a class="more" routerLink="/admin/feeds-management">
                        View more <i class="m-icon-swapright m-icon-white"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat purple">
                    <div class="visual">
                        <i class="fa fa-file" aria-hidden="true"></i>
                    </div>
                    <div class="details">
                        <div class="number">
                            {{totalReportedUser}}
                        </div>
                        <div class="desc">
                            ReportedUsers
                        </div>
                    </div>
                    <a class="more" routerLink="/admin/reports-management">
                        View more <i class="m-icon-swapright m-icon-white"></i>
                    </a>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat yellow">
                    <div class="visual">
                        <i class="fa fa-list-ul"></i>
                    </div>
                    <div class="details">
                        <div class="number">
                            {{responseData.posts}}
                        </div>
                        <div class="desc">
                           Reported Posts
                        </div>
                    </div>
                    <a class="more" routerLink="/admin/post_management">
                        View more <i class="m-icon-swapright m-icon-white"></i>
                    </a>
                </div>
            </div> -->
            <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <div class="dashboard-stat yellow">
                    <div class="visual">
                        <i class="fa fa-list-ul"></i>
                    </div>
                    <div class="details">
                        <div class="number">
                            {{responseData.story}}
                        </div>
                        <div class="desc">
                            Story
                        </div>
                    </div>
                    <a class="more">
                        View more <i class="m-icon-swapright m-icon-white"></i>
                    </a>
                </div>
            </div> -->

        </div>

    </div>
    <!--end tabbable-->
</div>