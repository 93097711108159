
<div class="logo">
  <a>
    <img src="assets/LOGO" alt="">
  </a>
</div>
<!-- END LOGO -->
<!-- BEGIN LOGIN -->
<div class="content">
  <form class="forget-form" method="post" style="display: block;" [formGroup]="forgotForm"
  (ngSubmit)="forgotPassword()">
  <h3>Forget Password ?</h3>
  <p>
    Enter your e-mail address below to reset your password.
  </p>

  <div class="form-group">
    <!--ie8, ie9 does not support html5 placeholder, so we just show field title for that-->
    <label class="control-label visible-ie8 visible-ie9">Email</label>
    <div class="input-icon"
      [ngClass]="{ 'has-error': submitted && f.email.errors,'has-success': submitted && !f.email.errors}">
      <i class="fa fa-envelope"></i>
      <input class="form-control placeholder-no-fix" type="text" autocomplete="on" placeholder="Email" name="email"
        formControlName="email">
    </div>
    <div *ngIf="submitted && f.email.errors" class="text-danger">
      <div *ngIf="f.email.errors.required">Email is required</div>
      <div *ngIf="f.email.errors.pattern"> Enter a valid email address</div>
    </div>
  </div>
  <div class="form-actions">
    <button type="button" id="back-btn" class="btn" routerLink="/login">
      <i class="m-icon-swapleft"></i> Back </button>
    <button type="submit" class="btn green pull-right">
      Submit <i class="m-icon-swapright m-icon-white"></i>
    </button>
  </div>
</form>
</div>
