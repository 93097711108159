import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-management',
  templateUrl: './subscription-management.component.html',
  styleUrls: ['./subscription-management.component.css']
})
export class SubscriptionManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
