import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-job-management',
  templateUrl: './job-management.component.html',
  styleUrls: ['./job-management.component.css']
})
export class JobManagementComponent implements OnInit {
  public title = 'Jobs Management';
  public responseData: any = [];
  public IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
  public search = '';
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;

  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    
    // stop here if form is invalid 
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    console.log(this.skip, this.itemsPerPage)
    let api_url = 'admin/job_management?skip=' + this.skip + '&limit=' + this.itemsPerPage + '&search=' + this.search;

    this.api.getJobs({skip : this.skip, limit : 10, search : this.search}).subscribe(
      (res:any) => {

        if (res != undefined && (res.code == 200 || res.code == 202)) {

          this.totalRecords = res.totalCount;
          this.responseData = res.data

        } else {
          this.notifier.notify('error',res.message)
            

          
        }
      },
      (err) => {
        console.log(err.message," this is main issue")
      }
    );

  }

  // activeInactiveUser(val, status) {

  //   Swal.fire({
  //     text: status ? 'Are you sure, You want to inactive this user ?' : 'Are you sure, You want to active this user?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Submit',
  //     cancelButtonText: 'Cancel'
  //   }).then((result) => {

  //     if (result.value) {

  //       this.api.postRequest('Admin/blockUnBlockUser', {
  //         user_id: val,
  //         block: status ? true : false
  //       }).then(
  //         (res) => {

  //           if (res && res.code == 200) {
  //             Swal.fire(
  //               'Success!',
  //               status ? 'User marked inactive successfully.' : 'User marked active successfully.',
  //               'success'
  //             )

  //             for (var i in this.responseData) {
  //               if (this.responseData[i]._id.toString() == val.toString()) {
  //                 this.responseData[i].isBlocked = status;
  //               }
  //             }

  //           } else {
  //             Swal.fire(
  //               'Something Went Wrong.',
  //               'Please try again after some time.',
  //               'error'
  //             )
  //           }
  //         },
  //         (err) => {
  //           Swal.fire(
  //             'Something Went Wrong.',
  //             'Please try again after some time.',
  //             'error'
  //           )
  //         }
  //       );
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire(
  //         'Cancelled',
  //         'Your request has been cancelled',
  //         'error'
  //       )
  //     }
  //   })
  // }

  pageChanged(val:any) {

    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getData()

  }

  deleteJob(userId:any) {
    Swal.fire({
      title: 'Are you sure, You want to delete this job ?',
      text: 'You will not be able to recover this job.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {

      if (result.value) {

        this.api.deleteJob(userId).subscribe(
          (res:any) => {
            if (res && res.code == 200) {
              Swal.fire(
                'Deleted!',
                'Post has been deleted.',
                'success'
              )
              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == userId.toString()) {
                  this.responseData.splice(i, 1);
                }
              }

            } else {

              Swal.fire(
                'Something Went Wrong.',
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {

            Swal.fire(
              'Something Went Wrong.',
              'Please try Again after some time.',
              'error'
            )
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }

}
