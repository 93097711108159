<div class="row">
  <div class="col-md-12">
    <h3 class="page-title"> {{title}} </h3>
    <ul class="page-breadcrumb breadcrumb">
      <li> <i class="fa fa-home"></i> <a href=""> Home </a> <i class="fa fa-angle-right"></i>
      </li>
      <li> <a href="javascript:void(0);"> {{title}} </a> </li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="row search-form-default">
      <div class="col-md-12">
        <form action="#" id="search">
          <div class="search-hero">
            <input class="form-control" type="text" name="search" (keyup)="getData()" [(ngModel)]="search"
              autocomplete="off" placeholder="&#61442;  Start searching for a job by title, consumer or artist">
          </div>
        </form>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th> Title</th>
            <th> Description</th>
            <th> Host </th>
            <th>Actions </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of responseData | filter: search | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords }; let i = index ">
            <td>{{skip+i+1}} </td>
            <td>
              {{data.title | titlecase}}
            </td>
            <td>{{data.description |  titlecase}}</td>
            <td> {{data.host[0] |  titlecase}}</td>
            <td>
              <i class="fa fa-trash-o" (click)="deleteJob(data._id)"></i>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    <p *ngIf="totalRecords==0">No results Found</p>
    <div class="margin-top-20" *ngIf="!search">
      <ul class="pagination">
        <pagination-controls (pageChange)="pageChanged(p = $event)" *ngIf="totalRecords > itemsPerPage"
          screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
          screenReaderCurrentLabel="You're on page">
        </pagination-controls>
      </ul>
    </div>
  </div>
</div>