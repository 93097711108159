import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './view/login/login.component';
import { ForgotPasswordComponent } from './view/forgot-password/forgot-password.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { SubscriptionComponent } from './view/subscription/subscription.component';

//services
import { CanActivateRouteGuard } from "./configs/can-activate-route.guard"
import { Interceptor } from './configs/interceptor';
// modules
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule} from 'ngx-pagination';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NotifierModule } from "angular-notifier";

// search module
import { Ng2SearchPipeModule } from 'ng2-search-filter';
// import { NgxCopyToClipboardModule } from 'ngx-copy-to-clipboard';
//services
import { HeaderComponent } from './view/header/header.component';
import { MainComponent } from './view/main/main.component';
import { FooterComponent } from './view/footer/footer.component';
import { SidebarComponent } from './view/sidebar/sidebar.component';
import { UserManagementComponent } from './view/user-management/user-management.component';
import { UserDetailComponent } from './view/user-detail/user-detail.component';
import { ChangePasswordComponent } from './view/change-password/change-password.component';
import { ContentManagementComponent } from './view/content-management/content-management.component';
import { PagenotfoundComponent } from './view/pagenotfound/pagenotfound.component';
import { CaptalisePipe } from './configs/captalise.pipe';
import { JobManagementComponent } from './view/job-management/job-management.component';
import { ViewCategoryComponent } from './view/view-category/view-category.component';
import { JobDetailComponent } from './view/job-management/job-detail/job-detail.component';
import { UpdateProfileComponent } from './view/update-profile/update-profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FeedsManagementComponent } from './view/feeds-management/feeds-management.component';
import { FeedDetailComponent } from './view/feed-detail/feed-detail.component';
import { ReportManagementComponent } from './view/report-management/report-management.component';
import { SubscriptionManagementComponent } from './view/subscription-management/subscription-management.component';
import { AdviceManagementComponent } from './view/advice-management/advice-management.component';
import { CategoryManagementComponent } from './view/category-management/category-management.component';
import { HobbiesManagementComponent } from './view/hobbies-management/hobbies-management.component';
import { ProffessionManagementComponent } from './view/proffession-management/proffession-management.component';
import { OutfitManagementComponent } from './view/outfit-management/outfit-management.component';
import { NanyManagementComponent } from './view/nany-management/nany-management.component';
import { QuestionsManagementComponent } from './view/questions-management/questions-management.component';
import { SpamManagementComponent } from './view/spam-management/spam-management.component';
import { ReelDetailComponent } from './view/reels-management/reel-detail.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    SidebarComponent,
    UserManagementComponent,
    UserDetailComponent,
    ChangePasswordComponent,
    ContentManagementComponent,
    PagenotfoundComponent,
    CaptalisePipe,
    JobManagementComponent,
    ViewCategoryComponent,
    JobDetailComponent,
    UpdateProfileComponent,
    SubscriptionComponent,
    FeedsManagementComponent,
    FeedDetailComponent,
    ReportManagementComponent,
    SpamManagementComponent,
    SubscriptionManagementComponent,
    AdviceManagementComponent,
    CategoryManagementComponent,
    HobbiesManagementComponent,
    ReelDetailComponent,
    ProffessionManagementComponent,
    OutfitManagementComponent,
    NanyManagementComponent,
    QuestionsManagementComponent,
    // ApiCallingService
  ],
  exports: [
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    CKEditorModule,
    NotifierModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot()
    // NgxCopyToClipboardModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    CanActivateRouteGuard
  ]
})
export class AppModule { }
