<div class="row">
  <div class="col-md-12">
    <h3 class="page-title">{{ title }}</h3>
    <ul class="page-breadcrumb breadcrumb">
      <li>
        <i class="fa fa-home"></i> <a href=""> Home </a>
        <i class="fa fa-angle-right"></i>
      </li>
      <li>
        <a href="javascript:void(0);"> {{ title }} </a>
      </li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="row search-form-default">
      <div class="col-md-12">
        <form action="#" id="search">
          <div class="search-hero">
            <input
              class="form-control"
              type="text"
              name="search"
              (keyup)="searchUser()"
              [(ngModel)]="search"
              autocomplete="off"
              placeholder="&#61442;  Start searching for a user by Reported By"
            />
          </div>
        </form>
      </div>
    </div>
    <div class="table-responsive table-setting">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Profile</th>
            <th>Email</th>
            <th>Spam User</th>
            <th>Report Count</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <!--  | filter: search | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords }-->
          <tr
            *ngFor="
              let data of responseData
                | filter : search
                | paginate
                  : {
                      itemsPerPage: itemsPerPage,
                      currentPage: p,
                      totalItems: totalRecords
                    };
              let i = index
            "
          >
            <td>{{ itemsPerPage * (p - 1) + i + 1 }}</td>
            <td>
              <div class="zoom">
                <img
                  src="{{
                    data.profile != 'default.jpg'
                      ? IMAGE_BASE_URL + data.userId + '/' + data.profile
                      : (IMAGE_BASE_URL + 'default.jpg' | lowercase)
                  }}"
                  alt="Avatar"
                  class="avatar"
                />
                <br />
                <p>{{ data?.userName }}</p>
              </div>
            </td>

            <td>{{ data?.userEmail }}</td>

            <td>{{ data?.userName }}</td>

            <td>{{ data?.totalSpamCount }}</td>

            <td>
              <a
                [routerLink]="[
                  '/admin/reports-management/user_detail/',
                  data?.userId
                ]"
              >
                <!-- <i class="fa fa-eye"></i> -->
              </a>

              <a (click)="deleteUser(data.reportedTo)"
                ><i class="fa fa-trash-o"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <p *ngIf="!length" class="text-center" style="font-size: x-large">
        No Record Found
      </p>
    </div>
    <p *ngIf="totalRecords == 0">No results Found</p>
    <div class="margin-top-20 text-center">
      <ul class="pagination">
        <pagination-controls
          (pageChange)="pageChanged($event)"
          (pageBoundsCorrection)="pageChanged($event)"
          maxSize="9"
          directionLinks="true"
          autoHide="true"
          responsive="true"
          previousLabel="Previous"
          nextLabel="Next"
          screenReaderPaginationLabel="Pagination"
          screenReaderPageLabel="page"
          screenReaderCurrentLabel="You're on page"
        ></pagination-controls>
      </ul>
    </div>
  </div>
</div>
