<div class="row">
    <div class="col-md-12">
      <h3 class="page-title"> {{title}} </h3>
      <ul class="page-breadcrumb breadcrumb">
        <li> <i class="fa fa-home"></i> <a href=""> Home </a> <i class="fa fa-angle-right"></i>
        </li>
        <li> <a href="javascript:void(0);"> {{title}} </a> </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <!-- <div class="row search-form-default">
          <div class="col-md-12">
            <form action="#" id="search">
              <div class="search-hero">
                <input class="form-control" type="text" name="search" (keyup)="getReportUserList()" [(ngModel)]="search"
                  autocomplete="off" placeholder="&#61442;  Start searching for a user by Reported By">
              </div>
            </form>
          </div>
        </div> -->
      <div class="row justify-content-end">
        <button class="btn btn-primary mr-5" data-toggle="modal" data-target="#exampleModalCenter">Add</button>
      </div>
      <div class="table-responsive table-setting">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th> Question </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            <!--  | filter: search | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords }-->
            <tr *ngFor="let data of responseData; let i = index ">
              <td>{{i+1}} </td>
              <td>{{data.questions}}</td>
              <td>
                <span><i class="fa fa-pencil" (click)="openModal(data);"></i></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><i class="fa fa-trash-o" (click)="deleteProfession(data);"></i></span>
              </td>
            </tr>
  
          </tbody>
        </table>
        <p *ngIf="responseData.length <= 0" class="text-center" style="font-size: x-large;">No Record Found</p>
      </div>
    </div>
  </div>
  
  
  
  
  <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
  
        <form #catForm="ngForm" (ngSubmit)="addCategory(catForm.value);catForm.resetForm();">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Add New Question</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                placeholder="Enter question" ngModel name="questions">
            </div>
          </div>
          <div class="modal-footer mt-0">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  
  <!-- edit modal -->
  <div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
  
        <form #editForm="ngForm" (ngSubmit)="editCategory(editForm.value);editForm.resetForm();">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Edit Question</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                placeholder="Enter question" ngModel value="{{selectedCategoryName}}" name="questions">
              <!-- <input type="hidden" ngModel value="{{selectedCategoryId}}" name="id"> -->
              </div>
          </div>
          <div class="modal-footer mt-0">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>