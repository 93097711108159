import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-outfit-management',
  templateUrl: './outfit-management.component.html',
  styleUrls: ['./outfit-management.component.css']
})
export class OutfitManagementComponent implements OnInit {

  private readonly notifier: NotifierService;
  responseData: any;
  totalCount: any;
  length: any;
  selectedTitle: any;
  selectedDescription: any;
  selectedCategory: any;
  categoryData: any;
  selectedId : any;
  openId: any;
  file: any = null;
  BASE_URL: any = this.api.IMAGE_BASE_URL

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,

  ) {
    this.notifier = notifierService;
  }

  title = "Outfit Management";

  ngOnInit(): void {
    // console.log(this.IMAGE_BASE_URL,"jsdhjk")
    this.getData();
    this.getCategories();
  }

  onFileSelected(e: any) {
    this.file = e.target.files[0]
  }

  openModal(data: any) {
    console.log(data._id," this is the data")
    $('#exampleModalCenter2').modal('toggle');
    this.selectedId = data._id; 
    this.selectedTitle = data.title;
    this.selectedDescription = data.description;
    this.selectedCategory = data.Category._id;
  }

  getData() {
    this.SpinnerService.show();
    this.api.getOutfits().subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          this.responseData = res['data']
          this.length = this.responseData.length
          this.totalCount = res['count'];
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();

          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        this.SpinnerService.hide();

        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        } else {
          this.notifier.notify('error', 'Client side error')

        }
      }
    );
  }

  addOutfit(data: any,form:any) {
    if (data.title.trim() == '' || data.description.trim() == '' || data.category.trim() == '' || this.file == null) {
      this.notifier.notify('error', 'Please fill values correctly')
    } else {
      $('#exampleModalCenter').modal('toggle');
      this.SpinnerService.show();
      if (data.category_name != '') {
        let formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('category', data.category);
        formData.append('image', this.file);
        this.api.addOutfit(formData).subscribe(
          (res) => {
            form.resetForm();
            console.log(res, "this is response")
            if (res['status'] && res['code'] == 200) {
              // this.responseData = res['result']
              // this.length = this.responseData.length
              // this.totalCount = res['count'];
              // console.log(this.totalCount)
              this.getData();
              this.SpinnerService.hide();
            } else {
              this.SpinnerService.hide();
              this.notifier.notify('error', res['message'])
            }
          },
          (err) => {
            this.SpinnerService.hide();
            if (err instanceof HttpErrorResponse) {
              this.notifier.notify('error', `Error ${err.status} Found`)
            } else {
              this.notifier.notify('error', 'Client side error')
            }
          }
        );
      } else {
        this.notifier.notify('error', 'Please fill category name');
      }
    }
  }

  editOutfit(data: any,form:any) {
      $('#exampleModalCenter2').modal('toggle');
      this.SpinnerService.show();
      if (data.category_name !== '') {
        let formData = new FormData();
        formData.append('title', data.title.trim() == '' ? this.selectedTitle : data.title);
        formData.append('description', data.description.trim() == '' ? this.selectedDescription : data.description);
        formData.append('category', data.category === '' ? this.selectedCategory : data.category);
        formData.append('file', this.file);
        formData.append('id',this.selectedId);
        this.api.updateOutfit(formData).subscribe(
          (res) => {
            if (res['status'] && res['code'] == 200) {
              form.resetForm();
              // this.responseData = res['result']
              // this.length = this.responseData.length
              // this.totalCount = res['count'];
              // console.log(this.totalCount)
              this.getData();
              this.SpinnerService.hide();
            } else {
              this.SpinnerService.hide();
              this.notifier.notify('error', res['message'])
            }
          },
          (err) => {
            this.SpinnerService.hide();
            if (err instanceof HttpErrorResponse) {
              this.notifier.notify('error', `Error ${err.status} Found`)
            } else {
              this.notifier.notify('error', 'Client side error')
            }
          }
        );
      } else {
        this.notifier.notify('error', 'Please fill category name');
      }
    
  }

  getCategories() {
    this.api.getCategories(2).subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          console.log(res, " this is response +")
          this.categoryData = res['result']

          this.length = this.categoryData.length
          this.totalCount = res['count'];
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();

          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        this.SpinnerService.hide();

        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        } else {
          this.notifier.notify('error', 'Client side error')

        }
      })
  }

  deleteOutfit(id: any) {
    Swal.fire({
      title: 'Are you sure, You want to delete this outfit ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No'
    }).then((result) => {
      if(result.isConfirmed){
        this.SpinnerService.show();
    this.api.deleteOutfit(id).subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          // this.responseData = res['result']
          // this.length = this.responseData.length
          // this.totalCount = res['count'];
          // console.log(this.totalCount)
          this.getData();
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();
          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        this.SpinnerService.hide();
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        } else {
          this.notifier.notify('error', 'Client side error')
        }
      }
    )
      }})
    
  }

}