<div class="row">
  <div class="col-md-12">
    <!-- BEGIN PAGE TITLE & BREADCRUMB-->
    <h3 class="page-title"> {{title}} </h3>
    <ul class="page-breadcrumb breadcrumb">
      <li> <i class="fa fa-home"></i>
        <a href="#"> Home </a> <i class="fa fa-angle-right"></i>
      </li>
      <li> <a href="javascript:void(0);"> {{title}} </a> </li>
    </ul>
    <!-- END PAGE TITLE & BREADCRUMB-->
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <p>
      <ckeditor [editor]="Editor" [(ngModel)]="htmlContent" ></ckeditor>
    </p>
    <div class="form-actions fluid">
      <div class="col-md-12">
        <button type="submit" class="btn green" (click)="submit()" >Update</button>
      </div>
    </div>
  </div>
  <!--end tabbable-->
</div>