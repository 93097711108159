import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;


@Component({
  selector: 'app-spam-management',
  templateUrl: './spam-management.component.html',
  styleUrls: ['./spam-management.component.css']
})
export class SpamManagementComponent implements OnInit {

  title = "Spam Management"
  public responseData: any = [];
  public IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
  public search = '';
  public length:any;
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  public totalCount:any;
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,

  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let email = localStorage.getItem('user')
    // this.responseData = []
    // this.length = this.responseData.length
    this.SpinnerService.show();
    this.api.getspamList({skip : 0, limit : 10000}).subscribe(
      (res) => {
        console.log(res,"Spam Data I have")

        if (res['status'] && res['code'] == 200) {
          console.log(res)
          this.responseData = res['changedata']
          console.log(JSON.stringify(this.responseData),"Eureka Eureka")
          this.length = this.responseData.length
          this.totalCount = res['count'];
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();

          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        console.log(err.message," this is my message")
          this.SpinnerService.hide();
        
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );

  }

  searchUser(){

    this.api.getspamList({skip : 0, limit : 10000,search : this.search}).subscribe(res=>{
      if (res['status'] && res['code'] == 200) {
        
        this.responseData = res['data']
        console.log(this.responseData,"data")
        this.length = this.responseData.length
        this.totalCount = res['count'];
        console.log(this.totalCount)
      } else {
        this.notifier.notify('error', res['message'])
      }

    },err=>{
      if (err instanceof HttpErrorResponse) {
        this.notifier.notify('error', `Error ${err.status} Found`)
      }else{
        this.notifier.notify('error', 'Client side error')
      }
    })
  }

  pageChanged(val) {
    this.p=val
    console.log(val,"val==")
    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getData()

  }

  deleteUser(userId){
   var email = localStorage.getItem('user')
    Swal.fire({
      title: 'Are you sure, You want to delete this user ?',
      
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      console.log(result,"ress==")
      if (result.value) {

        this.api.deleteReportedUser({id:userId}).subscribe(
          (res) => {
            if (res && res['code'] == 200) {
              Swal.fire(
                'Deleted!',
                'User has been deleted.',
                'success'
              )
              this.getData()


            } else {

              Swal.fire(
                `${res['message']}`,
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {
            if(err instanceof HttpErrorResponse){
              Swal.fire(
                `Error ${err.status} ${err.message}`,
                'Please try Again after some time.',
                'error'
              )
            }else{
              Swal.fire(
                'Client side error',
                'Please try Again after some time.',
                'error'
              )
            }
            
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }
}
