<div class="row">
  <div class="col-md-12">
    <h3 class="page-title"> {{title}} </h3>
    <ul class="page-breadcrumb breadcrumb">
      <li class="btn-group" routerLink="/admin/dashboard"> <a class="btn green dropdown-toggle "> <i
            class="fa fa-backward" aria-hidden="true"></i> <span> Back
          </span></a> </li>
      <li> <i class="fa fa-home"></i> <a> Home </a> <i class="fa fa-angle-right"></i>
      </li>
      <li> <a> {{title}} </a> </li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="search-form-default">
    <!-- <div class="portlet box green "> -->
    <div class="portlet-title">
      <div class="tools">
        <a href="" class="collapse">
        </a>
        <a href="#" data-toggle="modal" class="config">
        </a>
        <a href="" class="reload">
        </a>
        <a href="" class="remove">
        </a>
      </div>
    </div>
    <!-- <div class="portlet-body form"> -->
    <form [formGroup]="myForm" (ngSubmit)="submit()" class="form-horizontal" role="form">
      <div class="form-body">
        <!-- <div class="form-group row">

          <label class="col-md-3 col-form-label" for="password-input">Profile Picture</label>
          <div class="col-md-9">
            <img src="{{fileUrl != '' ? fileUrl:IMAGE_BASE_URL+profile}}" height="200" width="200">
            <input type="file" id="file" accept="video/*|image/*" (change)="showPreview($event)">
          </div>
        </div> -->
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="password-input"> Name<span class="text-danger">*</span></label>
          <div class="col-md-9">
            <input type="text" autocomplete="current-password" class="form-control" id="password-input"
              name="password-input" placeholder="Enter name" formControlName="name"
              [ngClass]="{ 'is-invalid': submitted &&  myForm.controls.name.errors }">
            <div class="text-danger" *ngIf="submitted  && myForm.controls.name.errors" class="invalid-feedback"
              [ngClass]="{ 'is-invalid': submitted && myForm.controls.name.value == ''}">
              <div class="text-danger" *ngIf="myForm.controls.name.errors.required">Name is required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-3 col-form-label" for="password-input"> Email<span class="text-danger">*</span></label>
          <div class="col-md-9">
            <input type="email" autocomplete="current-password" class="form-control" id="password-input"
              name="password-input" placeholder="Enter email" formControlName="email"
              [ngClass]="{ 'is-invalid': submitted &&  myForm.controls.email.errors }" disabled>
            <div class="text-danger" *ngIf="submitted  && myForm.controls.email.errors" class="invalid-feedback"
              [ngClass]="{ 'is-invalid': submitted && myForm.controls.email.value == ''}">
              <div class="text-danger" *ngIf="myForm.controls.email.errors.required">Email is required</div>
              <div class="text-danger" *ngIf="myForm.controls.email.errors.pattern"> Enter a valid email address</div>
              <!-- <div *ngIf="myForm.controls.new_password.errors.minlength">New Password must be at least 2 characters</div> -->
            </div>
          </div>
        </div>
        <div class="form-group row">
          <!-- <label class="col-md-3 col-form-label" for="password-input">Current Password</label> -->
          <!-- <div class="col-md-9">
            <input autocomplete="current-password" class="form-control" id="password-input" name="password-input"
              placeholder="Enter current password" type="password" formControlName="password"
              [ngClass]="{ 'is-invalid': submitted &&  myForm.controls.password.errors }">
            <div class="text-danger" *ngIf="submitted && myForm.controls.password.errors" class="invalid-feedback"
              [ngClass]="{ 'is-invalid': submitted && myForm.controls.password.value == ''}">
           
              <div *ngIf="showError" class="text-danger">Current password is required
              </div>
              <div *ngIf="myForm.controls.password.errors.minlength" class="text-danger">Password must be at least 2
                characters</div>
            </div>
          </div> -->
        </div>
        <!-- <div class="form-group row">
          <label class="col-md-3 col-form-label" for="password-input"> New Password</label>
          <div class="col-md-9">
            <input type="password" autocomplete="current-password" class="form-control" id="password-input"
              name="password-input" placeholder="Enter new password" formControlName="new_password"
              [ngClass]="{ 'is-invalid': submitted &&  myForm.controls.new_password.errors }">
            <div class="text-danger" *ngIf="submitted  && myForm.controls.new_password.errors" class="invalid-feedback"
              [ngClass]="{ 'is-invalid': submitted && myForm.controls.new_password.value == ''}">
              <div class="text-danger" *ngIf="myForm.controls.new_password.errors.required">New Password is required
              </div>
              <div *ngIf="showError" class="text-danger">New password is required
              </div>
            </div>
          </div>
        </div>
        <p style="color: coral;">Note- Leave blank if you don't want to change password.</p>
      -->
      </div>
      <div class="form-actions fluid">
        <div class="col-md-offset-3 col-md-9">
          <!-- <button type="button" class="btn default">Cancel</button> -->
          <button type="submit" class="btn green">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>