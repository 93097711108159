<div class="row">
  <div class="col-md-12">
    <h3 class="page-title">Category Detail</h3>
    <ul class="page-breadcrumb breadcrumb">
      <li class="btn-group" routerLink="/admin/category_management"> <a class="btn green dropdown-toggle "> <i
            class="fa fa-backward" aria-hidden="true"></i> <span> Back
          </span></a>
      </li>
      <li> <i class="fa fa-home"></i> <a href=""> Home </a> <i class="fa fa-angle-right"></i>
      </li>
      <li> <a> Category Detail </a> </li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="search-form-default">
    <!-- <div class="portlet box green "> -->
    <div class="portlet-title">
      <div class="tools">
        <a href="" class="collapse">
        </a>
        <a href="#" data-toggle="modal" class="config">
        </a>
        <a href="" class="reload">
        </a>
        <a href="" class="remove">
        </a>
      </div>
    </div>
    <!-- <div class="portlet-body form"> -->
    <div class="form-horizontal" role="form">
      <div class="form-body">
        <div class="form-group">
          <label class="col-md-3 control-label">Category name</label>
          <div class="col-md-9">
            <input disabled type="text" class="form-control" [(ngModel)]="category_name" placeholder="Enter category name">
            <span class="text-danger" *ngIf="submitted && category_name== ''">
              Category name is required
            </span>
          </div>
        </div>

        <div class="form-group">
          <label for="exampleInputFile" class="col-md-3 control-label">Category image</label>
          <div class="col-md-9">
            <div class="uploadImage">
              <img id="imgSrc" [src]="fileUrl != '' ? fileUrl:'../../../assets/img/no_image.png'" height="200">
              <input disabled type="file" id="file" name="inputFile" accept="image/*"
                (change)="handleFileInput($event.target.files)">
              <!-- <label for="file">Upload Image</label> -->
            </div>
            <span class="text-danger" *ngIf="submitted && !fileToUpload">
              File is required
            </span>

          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>