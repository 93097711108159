<div class="row">
  <div class="col-md-12">
    <h3 class="page-title"> {{title}} </h3>
    <ul class="page-breadcrumb breadcrumb">
      <li> <i class="fa fa-home"></i> <a href=""> Home </a> <i class="fa fa-angle-right"></i>
      </li>
      <li> <a href="javascript:void(0);"> {{title}} </a> </li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="row justify-content-end">
      <button class="btn btn-primary mr-5" data-toggle="modal" data-target="#exampleModalCenter">Add</button>
    </div>
    <div class="table-responsive table-setting">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th> Question</th>
            <th> Answer </th>
            <th>Status</th>
            <th> Action </th>
          </tr>
        </thead>
        <tbody>
          <!--  | filter: search | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords }-->
          <tr *ngFor="let data of responseData; let i = index ">
            <td>{{i+1}} </td>
            <td style="">{{data.que}}</td>
            <td style="">{{data.ans}}</td>
            <td>
              <span [ngClass]="data.active?'green':'red'">{{data.active?'Active':'Inactive'}}</span>
              
            </td>
            <td class="d-flex" style="gap:15px;">
              <span><i class="fa fa-pencil" (click)="openModal(data);"></i></span>
              <span><i class="fa fa-trash-o" (click)="deleteAdvice(data._id);"></i></span>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</div>




<!-- Modals -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">

      <form #catForm="ngForm" (ngSubmit)="addQuestion(catForm.value);catForm.resetForm();">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Add New Question</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <!-- <div class="form-group mt-3">
            <label>Choose category</label>
            <select class="form-control text-uppercase" id="exampleFormControlSelect1" ngModel required name="category_id">
              <option class="text-uppercase" value="" selected>{{categoryData.length <= 0 ? "No category added yet for advice" : "Choose category"}}</option>
              <option *ngFor="let item of categoryData" class=" text-uppercase" [value]="item._id">{{item.category_name}}</option>
            </select>
          </div> -->
            <textarea type="text" class="form-control mt-3" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Enter question" ngModel required name="que"></textarea>
            <textarea type="text" class="form-control mt-3" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Enter answer" ngModel required name="ans"></textarea>
          </div>
        </div>
        <div class="modal-footer mt-0">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary" [disabled]="!catForm.valid">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- edit modal -->
<div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">

      <form #editForm="ngForm" (ngSubmit)="editQuestion(editForm.value);">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Edit Question</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <!-- <div class="form-group mt-3">
            <label>Choose category</label>
            <select class="form-control text-uppercase" id="exampleFormControlSelect1" [(ngModel)]="scat" required name="category_id">
              <option *ngFor="let item of categoryData" [value]="item._id" class="text-uppercase">{{item.category_name}}</option>
            </select>
          </div> -->
            <textarea type="text" class="form-control mt-3" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Enter question" required name="que" [(ngModel)]="sque"></textarea>
            <textarea type="text" class="form-control mt-3" id="exampleInputEmail1" aria-describedby="emailHelp"
              placeholder="Enter answer" required name="ans" [(ngModel)]="sans"></textarea>
            <label class="switch mt-3">
              <input ngModel name="active" type="checkbox" [(ngModel)]="sstatus" [checked]="sstatus">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="modal-footer mt-0">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="submit" class="btn btn-primary">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>