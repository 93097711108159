import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-questions-management',
  templateUrl: './questions-management.component.html',
  styleUrls: ['./questions-management.component.css']
})
export class QuestionsManagementComponent implements OnInit {
  private readonly notifier: NotifierService;
  responseData: any;
  totalCount: any;
  length: any;
  selectedCategoryName: any;
  selectedCategoryId: any;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,

  ) {
    this.notifier = notifierService;
  }

  title = "Questions Management";

  ngOnInit(): void {
    // console.log(this.IMAGE_BASE_URL,"jsdhjk")
    this.getData();
  }

  openModal(data: any) {
    $('#exampleModalCenter2').modal('toggle');
    this.selectedCategoryName = data.questions;
    this.selectedCategoryId = data._id;
  }

  getData() {
    this.SpinnerService.show();
    this.api.getQuestions().subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          this.responseData = res['data']
          console.log(this.responseData, "data")
          this.length = this.responseData.length
          this.totalCount = res['count'];
          console.log(this.totalCount)
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();

          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        this.SpinnerService.hide();

        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        } else {
          this.notifier.notify('error', 'Client side error')

        }
      }
    );
  }

  addCategory(data: any) {
    this.SpinnerService.show();
    $('#exampleModalCenter').modal('toggle');
    if (data.name != '') {
      this.api.addQuestion(data).subscribe(
        (res) => {
          console.log(res, "this is response")
          if (res['status'] && res['code'] == 200) {
            // this.responseData = res['result']
            // this.length = this.responseData.length
            // this.totalCount = res['count'];
            // console.log(this.totalCount)
            this.getData();
            this.SpinnerService.hide();
          } else {
            this.SpinnerService.hide();
            this.notifier.notify('error', res['message'])
          }
        },
        (err) => {
          this.SpinnerService.hide();
          if (err instanceof HttpErrorResponse) {
            this.notifier.notify('error', `Error ${err.status} Found`)
          } else {
            this.notifier.notify('error', 'Client side error')
          }
        }
      );
    } else {
      this.notifier.notify('error', 'Please fill profession name');
    }
  }

  editCategory(data: any) {
    this.SpinnerService.show();
    $('#exampleModalCenter2').modal('toggle');
    if (data.name != '') {
      this.api.editQuestion({ ...data, id: this.selectedCategoryId }).subscribe(
        (res) => {
          if (res['status'] && res['code'] == 200) {
            // this.responseData = res['result']
            // this.length = this.responseData.length
            // this.totalCount = res['count'];
            // console.log(this.totalCount)
            this.getData();
            this.SpinnerService.hide();
          } else {
            this.SpinnerService.hide();
            this.notifier.notify('error', res['message'])
          }
        },
        (err) => {
          this.SpinnerService.hide();
          if (err instanceof HttpErrorResponse) {
            this.notifier.notify('error', `Error ${err.status} Found`)
          } else {
            this.notifier.notify('error', 'Client side error')
          }
        }
      );
    } else {
      this.notifier.notify('error', 'Please fill profession name');
    }
  }

  deleteProfession(data: any) {
    Swal.fire({
      title: 'Are you sure, You want to delete this question ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No'
    }).then((result) => {
      if(result.isConfirmed){
        this.SpinnerService.show();
    this.api.deleteQuestion({ questionId: data._id }).subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          this.getData();
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();
          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        this.SpinnerService.hide();
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        } else {
          this.notifier.notify('error', 'Client side error')
        }
      }
    );
      }})
    
  }

}
