<div class="row">
  <div class="col-md-12">
    <!-- BEGIN PAGE TITLE & BREADCRUMB-->
    <h3 class="page-title"> {{title}} </h3>
    <ul class="page-breadcrumb breadcrumb">
      <li> <i class="fa fa-home"></i> <a href=""> Home </a> <i class="fa fa-angle-right"></i>
      </li>
      <li> <a href="javascript:void(0);"> {{title}} </a> </li>
    </ul>
    <!-- END PAGE TITLE & BREADCRUMB-->
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="tabbable tabbable-custom tabbable-full-width">
      <ul class="nav nav-tabs">
        <li class="active"> <a> {{title}} </a>
        </li>
      </ul>

      <div class="col-md-12">
        <div class="col-md-6 price"> 
          <span class="price mt-2 mb-2"> Revenue Generated : ${{totalReveneue}} </span>
        </div>
      </div>

      <div class="tab-content">
        <div id="tab_1_5">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-advance table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th> Purchased By</th>
                  <th> Email </th>
                  <th> Plan</th>

                  <th> Amount</th>
                  <th>  Purchased On </th>
                  <!-- <th> Expired On </th> -->
                </tr>
              </thead>
              <tbody>

                <tr
                  *ngFor="let data of responseData  | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords }; let i = index ">
                  <td>{{ itemsPerPage * (p - 1) + i+1 }}  </td>
                  <td>{{data.fullName |captalise}}</td>
                  <td> {{data.email}} </td>
                  <td> {{data.purchaseType == '1' ?'BASIC': data.purchaseType == '2' ?'STANDARD':'PREMIUM'}}</td>
                  <!-- <td>{{data.purchaseType == '1' ?'$29.99': data.purchaseType == '2' ?'$49.99':'$59.99'}}</td> -->
                  <td> {{data.price}} </td>
                  <td>{{data.created_at | date:'fullDate'}}</td>

                  <!-- <td>{{data.expiryTime*1000 | date:'fullDate'}}</td> -->
                  <!-- <td>
                    <a [routerLink]="['/main/user_management/user_detail/',data._id]"> <i class="fa fa-eye fonta"></i>
                    </a> -->
                </tr>

              </tbody>
            </table>
             <p *ngIf="totalRecords==0" class="text-center" style="font-size: x-large;">No Record Found</p>
          </div>
          <!-- <p *ngIf="totalRecords==0">No results found</p> -->
          <div class="margin-top-20">
            <ul class="pagination">
              <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)" maxSize="9"
                directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous" nextLabel="Next"
                screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
                screenReaderCurrentLabel="You're on page">
                  
              </pagination-controls>

            </ul>
          </div>
        </div>
        <!--end tab-pane-->
      </div>
    </div>
     <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white" > Loading  </p>
  </ngx-spinner>
  </div>