import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  public title = 'Subscription Management';
  public responseData: any = [];
  public search = '';
  // pagination var
   itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  public totalReveneue:any;
  private readonly notifier: NotifierService;
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,


  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    // if (this.search) {
    //   this.p = 1;
    //   this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    // }
    this.SpinnerService.show()

    // let api_url = 'admin/subscription_management?skip=' + this.skip + '&limit=' + this.itemsPerPage + '&search=' + this.search + '&type=EMPLOYER';
    this.api.subscriptionList({page:this.p,itemsPerPage:this.itemsPerPage}).subscribe(
      (res) => {
        if (res["code"] == 200  && res['status']) {
        this.SpinnerService.hide()

          this.totalRecords = res["totalCount"];
          this.responseData = res['data'];
          this.totalReveneue = res['totalAmount']
        } else {
         
            this.notifier.notify('error', res['message'])

            this.SpinnerService.hide()

          
        }
      },
      (err) => {
        this.SpinnerService.hide()
        if(err instanceof HttpErrorResponse){
          this.notifier.notify('error', `Error Status ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')
        }
      }
    );

  }

  // activeInactiveUser(val, status) {

  //   Swal.fire({
  //     text: status ? 'Are you sure, You want to inactive this user ?' : 'Are you sure, You want to active this user?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Submit',
  //     cancelButtonText: 'Cancel'
  //   }).then((result) => {

  //     if (result.value) {

  //       this.api.postRequest('Admin/blockUnBlockUser', {
  //         user_id: val,
  //         block: status ? true : false
  //       }).then(
  //         (res) => {

  //           if (res && res.code == 200) {
  //             Swal.fire(
  //               'Success!',
  //               status ? 'User marked inactive successfully.' : 'User marked active successfully.',
  //               'success'
  //             )

  //             for (var i in this.responseData) {
  //               if (this.responseData[i]._id.toString() == val.toString()) {
  //                 this.responseData[i].isBlocked = status;
  //               }
  //             }

  //           } else {
  //             Swal.fire(
  //               'Something Went Wrong.',
  //               'Please try again after some time.',
  //               'error'
  //             )
  //           }
  //         },
  //         (err) => {
  //           Swal.fire(
  //             'Something Went Wrong.',
  //             'Please try again after some time.',
  //             'error'
  //           )
  //         }
  //       );
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire(
  //         'Cancelled',
  //         'Your request has been cancelled',
  //         'error'
  //       )
  //     }
  //   })
  // }

   pageChanged(val) {
    this.p=val
    console.log(val,"val==")
    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getData()

  }

  // deleteUser(userId) {
  //   Swal.fire({
  //     title: 'Are you sure, You want to delete this user ?',
  //     text: 'You will not be able to recover this user.',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, delete it!',
  //     cancelButtonText: 'No, keep it'
  //   }).then((result) => {

  //     if (result.value) {

  //       this.api.postRequest('Admin/deleteUser', { user_id: userId }).then(
  //         (res) => {
  //           if (res && res.code == 200) {
  //             Swal.fire(
  //               'Deleted!',
  //               'User has been deleted.',
  //               'success'
  //             )
  //             for (var i in this.responseData) {
  //               if (this.responseData[i]._id.toString() == userId.toString()) {
  //                 this.responseData.splice(i, 1);
  //               }
  //             }

  //           } else {

  //             Swal.fire(
  //               'Something Went Wrong.',
  //               'Please try Again after some time.',
  //               'error'
  //             )
  //           }

  //         },
  //         (err) => {

  //           Swal.fire(
  //             'Something Went Wrong.',
  //             'Please try Again after some time.',
  //             'error'
  //           )
  //         }
  //       );

  //     } else if (result.dismiss === Swal.DismissReason.cancel) {

  //       Swal.fire(
  //         'Cancelled',
  //         'Your request has been cancelled',
  //         'error'
  //       )
  //     }
  //   })
  // }

}
