import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public category_name = '';
  public submitted = false;
  public responseData: any = [];
  fileToUpload: File = null;
  public name : string = "";
  public fileUrl: any = '';
  public notify:any;
  constructor(

    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
  ) {
    this.notify = notifierService
  }

  ngOnInit() {
    this.name = localStorage.getItem('name')
  }

  logout() {
    let email = localStorage.getItem('userId')
    this.api.logout( {email:email}).subscribe(
      (res) => {
        if(res['status'] && res['code']==200){
          this.notify.notify('success',res['message'])
          localStorage.clear()
         
          this.router.navigate(['/login'])
          // alert(res['message'])
        }else{
          this.notify.notify('error',res['message'])

        }
  
      },err=>{
        if(err instanceof HttpErrorResponse){
          this.notify.notify('Server side Error','')
        }else{
          this.notify.notify('client side error','')
  
        }
      }
    );

  }


}
