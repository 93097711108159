import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
@Component({
  selector: 'app-feed-detail',
  templateUrl: './feed-detail.component.html',
  styleUrls: ['./feed-detail.component.css']
})
export class FeedDetailComponent implements OnInit {
  public title = 'User Detail';
  public responseData: any = [];
  public id: string = '';
  public IMAGE_BASE_URL;
  private readonly notifier: NotifierService;
  public userid:any;
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getData()
    })
    this.IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
  }

  getData() {

    // stop here if form is invalid 

    let api_url = 'admin/feed-detail?';
    api_url = api_url + '_id=' + this.id;
    let email =localStorage.getItem('user')
    this.api.getFeed({id:this.id}).subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          this.userid = res['results']['userId'];
          this.responseData = res['results']['images']
          console.log( this.responseData)
          
        } else {
          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );

  }

}
