import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-category-management',
  templateUrl: './category-management.component.html',
  styleUrls: ['./category-management.component.css']
})



export class CategoryManagementComponent implements OnInit {
  private readonly notifier: NotifierService;
  responseData : any;
  totalCount : any;
  length : any;
  selectedCategoryName : any;
  selectedCategoryId  : any;
  selectedType : any;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,

  ) {
    this.notifier = notifierService;
  }

  title = "Category Management";

  ngOnInit(): void {
    // console.log(this.IMAGE_BASE_URL,"jsdhjk")
    this.getData();
  }

  openModal(data : any){
    $('#exampleModalCenter2').modal('toggle');
    this.selectedCategoryName = data.category_name;
    this.selectedCategoryId = data._id;
    this.selectedType = data.type;
  }

  getData(){
    this.SpinnerService.show();
    this.api.getCategories(3).subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          console.log(res," dkjsbvfdsvkjfsbvkjfdfsnfklb")
          this.responseData = res['response']
          this.length = this.responseData.length
          this.totalCount = res['count'];
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();
          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
          this.SpinnerService.hide();
        
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );
  }

  addCategory(data:any){
    console.log(data," this is the data")
    $('#exampleModalCenter').modal('toggle');
    if(data.category_name != ''){
      this.api.addCategory(data).subscribe(
        (res) => {
          console.log(res,"this is response")
          if (res['status'] && res['code'] == 200) {
            // this.responseData = res['result']
            // this.length = this.responseData.length
            // this.totalCount = res['count'];
            // console.log(this.totalCount)
            this.getData();
            this.SpinnerService.hide();
          } else {
            this.SpinnerService.hide();
            this.notifier.notify('error', res['message'])
          }
        },
        (err) => {
            this.SpinnerService.hide();
          if (err instanceof HttpErrorResponse) {
            this.notifier.notify('error', `Error ${err.status} Found`)
          }else{
            this.notifier.notify('error', 'Client side error')
          }
        }
      );
    }else{
      this.notifier.notify('error','Please fill category name');
    }
  }

  editCategory(data:any){
    console.log(data," this is the data")
    $('#exampleModalCenter2').modal('toggle');
    if(data.category_name != ''){
      this.api.editCategory({...data,category_name : data?.category_name.toUpperCase() ?? "",id:this.selectedCategoryId}).subscribe(
        (res) => {
          if (res['status'] && res['code'] == 200) {
            this.notifier.notify('success',res['message'])
            // this.responseData = res['result']
            // this.length = this.responseData.length
            // this.totalCount = res['count'];
            // console.log(this.totalCount)
            this.getData();
            this.SpinnerService.hide();
          } else {
            this.SpinnerService.hide();
            this.notifier.notify('error', res['message'])
          }
        },
        (err) => {
            this.SpinnerService.hide();
          if (err instanceof HttpErrorResponse) {
            this.notifier.notify('error', `Error ${err.status} Found`)
          }else{
            this.notifier.notify('error', 'Client side error')
          }
        }
      );
    }else{
      this.notifier.notify('error','Please fill category name');
    }
  }

  deleteCategory(id:any){
    Swal.fire({
      title: 'Are you sure, You want to delete this category ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No'
    }).then((result) => {
      if(result.isConfirmed){
        this.SpinnerService.show();
      this.api.deleteCategory(id).subscribe(
        (res) => {
          if (res['status'] && res['code'] == 200) {
            this.getData();
            this.SpinnerService.hide();
          } else {
            this.SpinnerService.hide();
            this.notifier.notify('error', res['message'])
          }
        },
        (err) => {
            this.SpinnerService.hide();
          if (err instanceof HttpErrorResponse) {
            this.notifier.notify('error', `Error ${err.status} Found`)
          }else{
            this.notifier.notify('error', 'Client side error')
          }
        }
      );
      }
    }).catch(err=>{
      
    })
    
  }

}
