import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-advice-management',
  templateUrl: './advice-management.component.html',
  styleUrls: ['./advice-management.component.css']
})

export class AdviceManagementComponent implements OnInit {

  private readonly notifier: NotifierService;
  responseData : any;
  totalCount : any;
  length : any;
  categoryData : any;
  sque : any;
  sans : any;
  scat : any;
  sstatus : any;
  id : any;
  editForm : NgForm;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private SpinnerService: NgxSpinnerService,

  ) {
    this.notifier = notifierService;
  }

  title = "Advice Management";

  ngOnInit(): void {
    this.getData();
    this.getCategories();
  }

  openModal(info:any){
    $('#exampleModalCenter2').modal('toggle');
    console.log(info,"this is my information");
    this.sque = info.que;
    this.sans = info.ans;
    this.scat = info.category_id;
    this.sstatus = info.active;
    this.id = info._id;
  }

  getData(){
    this.SpinnerService.show();
    this.api.getAdvices().subscribe(
      (res) => {
        if (res['status'] && res['code'] == 200) {
          this.responseData = res['result']
          this.length = this.responseData.length
          this.totalCount = res['count'];
          this.SpinnerService.hide();
        } else {
          this.SpinnerService.hide();

          this.notifier.notify('error', res['message'])
        }
      },
      (err) => {
          this.SpinnerService.hide();
        
        if (err instanceof HttpErrorResponse) {
          this.notifier.notify('error', `Error ${err.status} Found`)
        }else{
          this.notifier.notify('error', 'Client side error')

        }
      }
    );
    }

    getCategories(){
      this.api.getCategories(1).subscribe(
        (res) => {
          if (res['status'] && res['code'] == 200) {
            this.categoryData = res['result']
            this.length = this.categoryData.length
            this.totalCount = res['count'];
            this.SpinnerService.hide();
          } else {
            this.SpinnerService.hide();
  
            this.notifier.notify('error', res['message'])
          }
        },
        (err) => {
            this.SpinnerService.hide();
          
          if (err instanceof HttpErrorResponse) {
            this.notifier.notify('error', `Error ${err.status} Found`)
          }else{
            this.notifier.notify('error', 'Client side error')
  
          }
        })
    }

    addQuestion(data:any){
      if(data.que == null || data.que == '' || data.que.trim() == ''){
        this.notifier.notify('error','Question can not be empty')
      }else if(data.ans == null || data.ans == '' || data.ans.trim() == ''){
        this.notifier.notify('error','Answer can not be empty')
      }else{
        this.api.addAdvice(data).subscribe(
          (res) => {
            console.log(res,"this is response")
            if (res['status'] && res['code'] == 200) {
              $('#exampleModalCenter').modal('toggle');
              // this.responseData = res['result']
              // this.length = this.responseData.length
              // this.totalCount = res['count'];
              // console.log(this.totalCount)
              this.notifier.notify('success','Question added successfully')
              this.getData();
              this.SpinnerService.hide();
            } else {
              this.SpinnerService.hide();
              this.notifier.notify('error', res['message'])
            }
          },
          (err) => {
              this.SpinnerService.hide();
            if (err instanceof HttpErrorResponse) {
              this.notifier.notify('error', `Error ${err.status} Found`)
            }else{
              this.notifier.notify('error', 'Client side error')
            }
          }
        );
      }
    }

    editQuestion(data:any){
      console.log(data,"rthis is sm fjdfsbgbdgubbidrgbdrugibgd")
      if(data.que == null || data.que == '' || data.que.trim() == ''){
        this.notifier.notify('error','Question can not be empty')
      }else if(data.ans == null || data.ans == '' || data.ans.trim() == ''){
        this.notifier.notify('error','Answer can not be empty')
      }else{

        this.api.editAdvice({...data,id:this.id}).subscribe(
          (res) => {
            console.log(res,"this is response")
            if (res['status'] && res['code'] == 200) {
              $('#exampleModalCenter2').modal('toggle');
              // this.responseData = res['result']
              // this.length = this.responseData.length
              // this.totalCount = res['count'];
              // console.log(this.totalCount)
              this.notifier.notify('success','Question updated successfully')
              this.getData();
              this.SpinnerService.hide();
            } else {
              this.SpinnerService.hide();
              this.notifier.notify('error', res['message'])
            }
          },
          (err) => {
              this.SpinnerService.hide();
            if (err instanceof HttpErrorResponse) {
              this.notifier.notify('error', `Error ${err.status} Found`)
            }else{
              this.notifier.notify('error', 'Client side error')
            }
          }
        );
      }
    }


    deleteAdvice(id:any){
      Swal.fire({
        title: 'Are you sure, You want to delete this advice ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No'
      }).then((result) => {
        if(result.isConfirmed){
          this.SpinnerService.show();
        this.api.deleteAdvice(id).subscribe(
          (res) => {
            if (res['status'] && res['code'] == 200) {
              this.notifier.notify('success','Question updated successfully')
              this.getData();
              this.SpinnerService.hide();
            } else {
              this.SpinnerService.hide();
              this.notifier.notify('error', res['message'])
            }
          },
          (err) => {
              this.SpinnerService.hide();
            if (err instanceof HttpErrorResponse) {
              this.notifier.notify('error', `Error ${err.status} Found`)
            }else{
              this.notifier.notify('error', 'Client side error')
            }
          }
        );
        }}).catch(err => {

        })
    }
}