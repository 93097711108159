<!DOCTYPE html>
<html>
<head>
  <style>
    /* Reset default margin and padding */
    body, h1, h2, h3, p, div {
      margin: 0;
      padding: 0;
    }

    /* Basic styling for header, sidebar, and footer */
    .app-header, .app-footer {
      background-color: #333;
      color: #fff;
      text-align: center;
      padding: 10px;
    }

    .app-sidebar {
      background-color: #555;
      color: #fff;
      padding: 10px;
    }

    /* Page container */
    .page-container {
      display: flex;
      min-height: 100vh;
    }

    /* Sidebar */
    .sidebar {
      flex: 0 0 250px; /* Sidebar width */
      background-color: #555;
      color: #fff;
      /* padding: 10px; */
    }

    /* Page content area */
    .page-content {
      flex: 1;
      padding: 20px;
      background-color: #f0f0f0;
    }
  </style>
</head>
<body>
  <div class="app-header">
    <app-header></app-header>
  </div>
  
  <div class="page-container">
    <div class="sidebar">
      <app-sidebar></app-sidebar>
    </div>
    
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  
  <div class="app-footer">
    <app-footer></app-footer>
  </div>
</body>
</html>
