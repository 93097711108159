import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public activeRoute: boolean = false;
  public dp: string = '';
  constructor(
  ) { }

  ngOnInit() {

  }

  changeDropdown() {
    if (this.dp == 'open') {
      $('#lichange').removeClass("")
      $('#cont').css('display', 'none')
      this.dp = '';
    } else {
      $('#lichange').addClass("open")
      $('#cont').css('display', 'block')
      this.dp = 'open';
    }
  }

}
