<div class="row">
  <div class="col-md-12">
    <!-- BEGIN PAGE TITLE & BREADCRUMB-->
    <h3 class="page-title"> {{title}} </h3>
    <ul class="page-breadcrumb breadcrumb">
      <!-- <li class="btn-group" routerLink="/admin/user_management"> <a class="btn green dropdown-toggle "> <i
            class="fa fa-backward" aria-hidden="true"></i> <span> Back
          </span></a> </li> -->
      <li> <i class="fa fa-home"></i> <a> Home </a> <i class="fa fa-angle-right"></i>
      </li>
      <li> <a href="javascript:void(0);"> {{title}} </a> </li>
    </ul>
    <!-- END PAGE TITLE & BREADCRUMB-->
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <form>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Profile Picture</label>
        <div class="col-md-9">
          
          <img class="imgDisp" width="200" height="200"
            src="{{responseData.profile=='default.jpg'? IMAGE_BASE_URL +'default.jpg': IMAGE_BASE_URL+id+'/'+responseData.profile}}">
           <div>

             <img  src="{{isVerified?'../../../assets/img/verified.png':'../../../assets/img/notverified.png'}}" alt="" srcset="" >
           </div>
        </div>
      </div>
      <!-- <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Login Type</label>
        <div class="col-sm-10">
          <span
            [ngClass]="(responseData.socialId==undefined)?'badge badge-info':'badge badge-dark'">{{responseData.socialId == undefined ? 'Manual':'Social login'}}</span>
        </div>
      </div> -->
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Fullname</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.fullName || ''">
        </div>
      </div>

      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Username</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.userName || ''">
        </div>
      </div>
      <!-- <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Username</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.username">
        </div>
      </div> -->
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Device</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.deviceModel || '' ">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.email || ''">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Mobile Number</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.mobileNumber || '----'">
        </div>
      </div>
      <div class="separator">Basic Information </div>
      <br>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Age</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" name="age" [value]="screens1['age'] || '----' ">
        </div>
      </div>
     
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Gender</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="screens1.gender || '----' ">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Hobbies</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="screens1.hobbies || '----' ">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Profession</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="screens1.profession || '----' ">
        </div>
      </div>
      <div class="separator">Profile Pictures</div> <br>
      <!-- {{responseData.screens[1].images}} -->
      <div class="form-group row">
        <!-- <label for="staticEmail" class="col-sm-2 col-form-label">{{responseData.screens[1].images.length?'Profile Pictures':'Profile pictures are not available'}} </label> -->
        <div class="col-sm-10">
         <p style="font-weight: bold;" *ngIf="!ProfileLength">Not available </p>
     
        <div id="carouselExampleIndicators" class="carousel slide pfl-carousel" data-ride="carousel">
          <ol class="carousel-indicators" >
            <li data-target="#carouselExampleCaptions"  *ngFor="let x of screens2;let index = index" [attr.data-slide-to]="index==0"></li>

            <!-- <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li> -->
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let x of screens2;let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
              <img [src]="IMAGE_BASE_URL+id+'/'+x" class="d-block w-100" style="height: 200px; width: 150px;">
              <div class="carousel-caption d-none d-md-block">
             
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div> 
    
      </div>
      <div class="separator">Prefrences</div> <br>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Age Range Start</label>
       
        <div class="col-sm-10">
         
          <input type="text" readonly class="form-control" [value]="screens3.ageRangeStart || '----' ">
          
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Age Range End</label>
       
        <div class="col-sm-10">
         
          <input type="text" readonly class="form-control" [value]="screens3.ageRangeEnd || '----' ">
          
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Interested In</label>
       
        <div class="col-sm-10">
         
          <input type="text" readonly class="form-control" [value]="screens3.intrestedIn || '----' ">
          
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Miles</label>
       
        <div class="col-sm-10">
        
          <input type="text" readonly class="form-control" [value]="screens3.location || '----' ">
          
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Professions</label>
       
        <div class="col-sm-10">
         
          <input type="text" readonly class="form-control" [value]="screens3.showProfessions || '----' ">
          
        </div>
      </div>

      <div class="separator">OCR DOCUMENT</div> <br>

      <div class="form-group row">
        <!-- <label for="staticEmail" class="col-sm-2 col-form-label">Ocr Document</label> -->
        <!-- <div class="row " *ngFor="let x of responseData.screens[3].ocr_docs">
          <img class="imgDisp " width="200" height="200"
          src="{{IMAGE_BASE_URL+id+'/'+x}}">
        </div> -->
        <div class="col-sm-10">
          <p style="font-weight: bold;" *ngIf = "!isVerified"> Not Available</p>
          <div  id="carouselExampleCaptions " class="carousel slide pfl-carousel" data-ride="carousel">
           <!-- <div *ngFor="let item of responseData;let index = index"></div> -->
           <!-- <ol class="carousel-indicators">
             <li data-target="#carouselExampleCaptions"  *ngFor="let item of responseData;let index = index" [attr.data-slide-to]="index"></li>
           </ol> -->
           <div class="carousel-inner" *ngIf = "isVerified">
             <div class="carousel-item" *ngFor="let x of responseData.screens[3].ocr_docs;let index = index;let isFirst = first" [ngClass]="{active:isFirst}">
               <img [src]="IMAGE_BASE_URL+id+'/'+x" class="d-block w-100" style="height: 200px; width: 150px;">
               <div class="carousel-caption d-none d-md-block">
                 <!-- <h5 [textContent]="item.title"></h5>
                 <p [textContent]="item.symmery"></p> -->
               </div>
             </div>
           </div>
           <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
             <span class="carousel-control-prev-icon" aria-hidden="true"></span>
             <span class="sr-only">Previous</span>
           </a>
           <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
             <span class="carousel-control-next-icon" aria-hidden="true"></span>
             <span class="sr-only">Next</span>
           </a>
         </div></div> 
      </div>
      <!-- <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Device Token</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.deviceToken">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">Device Model</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.deviceModel">
        </div>
      </div>
      <div class="form-group row">
        <label for="staticEmail" class="col-sm-2 col-form-label">App Version</label>
        <div class="col-sm-10">
          <input type="text" readonly class="form-control" [value]="responseData.appVersion">
        </div>
      </div> -->
    </form>
  </div>
  <!--end tabbable-->

</div>