import { environment } from '../environments/environment';

export const endPoints = {
    LOG_IN: environment.SERVER_URL + '/admin/login',
    USERS: environment.SERVER_URL + '/admin/user-management/users',
    USER_DETAIL: environment.SERVER_URL + '/admin/user-management/user_detail',
    BLOCK_USER: environment.SERVER_URL + '/admin/user-management/blockUser',
    UN_BLOCK_USER: environment.SERVER_URL + '/admin/user-management/unblockUser',
    DELETE_USER: environment.SERVER_URL + '/admin/user-management/deleteUser',
    CHANGE_PASSWORD: environment.SERVER_URL + '/admin/change-password',
    FORGOT_PASSWORD: environment.SERVER_URL + '/admin/forgot-password',
    LOGOUT: environment.SERVER_URL + '/admin/logout',
    UPDATE_PROFILE: environment.SERVER_URL +'/admin/update-profile',
    TERMS_CONDITION: environment.SERVER_URL +'/admin/termCondition',
    PRIVACY_POLICY: environment.SERVER_URL +'/admin/privacyPolicy',
    GET_PRIVACY_POLICY: environment.SERVER_URL +'/admin/privacy_policy',
    GET_TERMS_CONDITION: environment.SERVER_URL +'/admin/term-condition',
    GENERATE_PROMO_CODE: environment.SERVER_URL +'/generate-promo-code',
    PROMO_CODE_LIST: environment.SERVER_URL +'/promo-code-list',
    DELETE_PROMO_CODE: environment.SERVER_URL + '/delete',
    GET_FEEDS: environment.SERVER_URL +'/admin/getFeeds',
    GET_FEED: environment.SERVER_URL +'/admin/getFeed',
    DELETE_FEED: environment.SERVER_URL +'/admin/deleteFeed',
    REPORT_USER_LIST: environment.SERVER_URL +'/admin/report-user-list',
    DELETE_REPORT_USER_LIST:environment.SERVER_URL+'/admin/delete-report-user-list',
    GENERATE_KEYS:environment.SERVER_URL+'/generateKeys',
    SEARCH:environment.SERVER_URL+'/search',
    FEED_SEARCH:environment.SERVER_URL+'/searchFeeds',
    SUBSCRIPTION_LIST:environment.SERVER_URL+'/subscription-list',
    CATEGORIES_LIST:environment.SERVER_URL+'/admin/get-adviceCat',
    ADD_CATEGORY:environment.SERVER_URL+'/admin/add-adviceCategory',
    EDIT_CATEGORY:environment.SERVER_URL+'/admin/edit-adviceCat',
    DELETE_CATEGORY:environment.SERVER_URL+'/admin/delete-category',
    GET_ADVICES:environment.SERVER_URL+'/admin/get-advices',
    ADD_ADVICE:environment.SERVER_URL+'/admin/add-advices',
    EDIT_ADVICE:environment.SERVER_URL+'/admin/edit-advice',
    DELETE_ADVICE:environment.SERVER_URL+'/admin/delete-advice',
    GET_HOBBIES:environment.SERVER_URL+'/get-hobbies',
    GET_PROFESSIONS:environment.SERVER_URL+'/get-profession',
    ADD_HOBBIES:environment.SERVER_URL+'/add-hobbies',
    ADD_PROFESSIONS:environment.SERVER_URL+'/add-profession',
    EDIT_HOBBIES:environment.SERVER_URL+'/update-hobbies',
    EDIT_PROFESSIONS:environment.SERVER_URL+'/update-profession',
    DELETE_HOBBIES:environment.SERVER_URL+'/delete-hobbies',
    DELETE_PROFESSIONS:environment.SERVER_URL+'/delete-profession',
    ADD_OUTFIT:environment.SERVER_URL+'/add-outfits',
    UPDATE_OUTFIT:environment.SERVER_URL+'/update-outfits',
    GET_OUTFITS:environment.SERVER_URL+'/get-outfitdata',
    GET_NANNY:environment.SERVER_URL+'/admin/get-nannyList',
    GET_JOBS:environment.SERVER_URL+'/admin/get-jobList',
    DELETE_OUTFITS:environment.SERVER_URL+'/delete-outfits',
    BLOCK_UNBLOCK_NANNY:environment.SERVER_URL+'/admin/blockUnblock-nanny/',
    DELETE_NANNY:environment.SERVER_URL+'/admin/delete-nanny/',
    DELETE_JOB:environment.SERVER_URL+'/admin/delete-job/',
    REEL_LIST : environment.SERVER_URL+'/admin/reel-list',
    GET_QUESTIONS:environment.SERVER_URL+'/get-question',
    ADD_QUESTION:environment.SERVER_URL+'/add-question',
    EDIT_QUESTION:environment.SERVER_URL+'/update-question',
    DELETE_QUESTION:environment.SERVER_URL+'/delete-question',
    SPAM_USER_LIST : environment.SERVER_URL + '/admin/getspamList'
}