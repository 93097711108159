import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.prod';
import { NgxSpinnerService } from "ngx-spinner";
import { NotifierService } from 'angular-notifier';
import { FormGroup, AbstractControl, Validators, ValidationErrors, FormBuilder } from '@angular/forms';
import { endPoints } from '../app.setting'
import * as JsEncryptModule from 'jsencrypt';

@Injectable({
  providedIn: 'root'
})
export class ApiCallingService {
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private notifierService: NotifierService,
  ) {
    this.notifier = notifierService;
  }

  public setUserLoggedIn(token: string, data: any): boolean {
    localStorage.setItem('token', token);
    localStorage.setItem('userDetails', JSON.stringify(data));
    return true;
  }

  public checkUserAuth(): boolean {
    return localStorage.token ? true : false;
  }

  public IMAGE_BASE_URL = environment.IMG_BASE_URL;

  public login(obj: any) {
    // let encryptedData = encrypt(obj)
    // console.log(encryptedData,"======")
    return this.http.post(endPoints.LOG_IN, obj);
  }

  public forgot_password(obj: any) {
    return this.http.post(endPoints.FORGOT_PASSWORD, obj);
  }

  public getCategories(type:any){
    return this.http.get(endPoints.CATEGORIES_LIST+'?type='+type);
  }

  public getOutfits(){
    return this.http.get(endPoints.GET_OUTFITS);
  }

  public getNanny(obj:any){
    return this.http.get(`${endPoints.GET_NANNY}?skip=${obj.skip}&limit=${obj.limit}&search=`);
  }

  public getJobs(obj:any){
    return this.http.get(`${endPoints.GET_JOBS}?skip=${obj.skip}&limit=${obj.limit}&search=`);
  }

  public addCategory(data:any){
    return this.http.post(endPoints.ADD_CATEGORY,data);
  }

  public deleteCategory(id:any){
    return this.http.delete(endPoints.DELETE_CATEGORY+'/'+id);
  }

  public blockUnblockNanny(id:any){
    return this.http.post(endPoints.BLOCK_UNBLOCK_NANNY+id,{});
  }
  
  public deleteNanny(id:any){
    return this.http.delete(endPoints.DELETE_NANNY+id);
  }

  public deleteJob(id:any){
    return this.http.delete(endPoints.DELETE_JOB+id);
  }

  // public reel_List(id : any){
  //   return this.http.get(endPoints.REEL_LIST+id);
  // }

  public reel_List(obj:any){
    return this.http.get(`${endPoints.SPAM_USER_LIST}?skip=${obj.skip}&limit=${obj.limit}&search=`);
  }

  public addOutfit(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token'))
    const httpOptions = {
      headers: headers_object
    };
    return this.http.post(endPoints.ADD_OUTFIT,data,httpOptions);
  }

  public updateOutfit(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token'))
    const httpOptions = {
      headers: headers_object
    };
    return this.http.patch(endPoints.UPDATE_OUTFIT,data,httpOptions);
  }

  public getHobbies(){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token')).set("pagenumber","0").set("limit","10");
    const httpOptions = {
      headers: headers_object
    };
    return this.http.get(endPoints.GET_HOBBIES,httpOptions);
  }

  public addHobbies(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token')).set("pagenumber","0").set("limit","10");
    const httpOptions = {
      headers: headers_object
    };
    return this.http.post(endPoints.ADD_HOBBIES,data,httpOptions);
  }

  public editHobbies(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token')).set("pagenumber","0").set("limit","10");
    const httpOptions = {
      headers: headers_object
    };
    return this.http.put(endPoints.EDIT_HOBBIES,data,httpOptions);
  }

  public deleteHobbies(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token')).set("pagenumber","0").set("limit","10");
    const httpOptions = {
      headers: headers_object
    };
    return this.http.delete(`${endPoints.DELETE_HOBBIES}/${data.hobbiesId}`,httpOptions);
  }

  public getProfessions(){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token')).set("pagenumber","0").set("limit","10");
    const httpOptions = {
      headers: headers_object
    };
    return this.http.get(endPoints.GET_PROFESSIONS,httpOptions);
  }

  public addProffesion(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token')).set("pagenumber","0").set("limit","10");
    const httpOptions = {
      headers: headers_object
    };
    return this.http.post(endPoints.ADD_PROFESSIONS,data,httpOptions);
  }

  public editProffesion(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token')).set("pagenumber","0").set("limit","10");
    const httpOptions = {
      headers: headers_object
    };
    return this.http.put(endPoints.EDIT_PROFESSIONS,data,httpOptions);
  }

  public deleteProffesion(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token')).set("pagenumber","0").set("limit","10");
    const httpOptions = {
      headers: headers_object
    };
    return this.http.delete(`${endPoints.DELETE_PROFESSIONS}/${data.professionId}`,httpOptions);
  }

  // questions
  public getQuestions(){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token')).set("pagenumber","0").set("limit","10");
    const httpOptions = {
      headers: headers_object
    };
    return this.http.get(endPoints.GET_QUESTIONS,httpOptions);
  }

  public addQuestion(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token'));
    const httpOptions = {
      headers: headers_object
    };
    return this.http.post(endPoints.ADD_QUESTION,data,httpOptions);
  }

  public editQuestion(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token'));
    const httpOptions = {
      headers: headers_object
    };
    return this.http.put(endPoints.EDIT_QUESTION,data,httpOptions);
  }

  public deleteQuestion(data:any){
    var headers_object = new HttpHeaders().set("x-token", localStorage.getItem('token'));
    const httpOptions = {
      headers: headers_object
    };
    console.log(data," this is data")
    return this.http.delete(`${endPoints.DELETE_QUESTION}/${data.questionId}`,httpOptions);
  }

  public editCategory(data:any){
    return this.http.put(endPoints.EDIT_CATEGORY,data);
  }

  public deleteOutfit(id:any){
    return this.http.post(endPoints.DELETE_OUTFITS+'?id='+id,{});
  }

  public getAdvices(){
    return this.http.get(endPoints.GET_ADVICES);
  }

  public addAdvice(data:any){
    return this.http.post(endPoints.ADD_ADVICE,data);
  }

  public editAdvice(data:any){
    return this.http.put(endPoints.EDIT_ADVICE,data);
  }

  public deleteAdvice(id:any){
    return this.http.delete(endPoints.DELETE_ADVICE+'/'+id);
  }

  public logout(obj: any) {
    return this.http.post(endPoints.LOGOUT, obj)
  }

  public getUsers(obj:any) {
    return this.http.post(endPoints.USERS,obj)
  }
  public user_detail(obj: any) {
    return this.http.post(endPoints.USER_DETAIL , obj)
  }
  public getFeed(obj: any) {
    return this.http.post(endPoints.GET_FEED , obj)
  }
  public blockUser(obj: any) {
    return this.http.put(endPoints.BLOCK_USER,obj)
  }

  public unblockUser(obj: any) {
    return this.http.put(endPoints.UN_BLOCK_USER,obj)
  }
  public deleteUser(obj: any) {
    return this.http.post(endPoints.DELETE_USER,obj)
  }
  public deleteFeed(obj: any) {
    return this.http.post(endPoints.DELETE_FEED,obj)
  }

  public changePassword(obj: any) {
    return this.http.put(endPoints.CHANGE_PASSWORD,obj)
  }
  public updateProfile(obj: any) {
    return this.http.put(endPoints.UPDATE_PROFILE,obj)
  }

  public privacy_policy(obj: any) {
    return this.http.post(endPoints.PRIVACY_POLICY,obj)
  }
  public terms_condtion(obj: any) {
    return this.http.post(endPoints.TERMS_CONDITION,obj)
  }

  public get_terms_condtion(obj:any) {
    return this.http.get(endPoints.GET_TERMS_CONDITION,obj)
  } 
  
  public get_privacy_policy(obj:any) {
    return this.http.get(endPoints.GET_PRIVACY_POLICY,obj)
  }

  public generate_promo_code(obj: any) {
    return this.http.post(endPoints.GENERATE_PROMO_CODE, obj)
  }


  public get_promo_code_list(obj: any) {
    return this.http.post(endPoints.PROMO_CODE_LIST, obj)
  }

  public deletePromoCode(obj: any) {
    return this.http.post(endPoints.DELETE_PROMO_CODE, obj)
  }
  public getFeeds(obj: any) {
    return this.http.post(endPoints.GET_FEEDS, obj)
  }
  public reportUserList() {
    return this.http.get(endPoints.REPORT_USER_LIST)
  }
  public spamuserList(){
    return this.http.get(endPoints.SPAM_USER_LIST)
  }
  public deleteReportedUser(obj:any){
    return this.http.post(endPoints.DELETE_REPORT_USER_LIST,obj)
  }

  public generateKeys(){
    return this.http.get(endPoints.GENERATE_KEYS)
  }

  public searchUser(obj:any){
    return this.http.post(endPoints.SEARCH,obj)
  }


  public searchFeed(obj:any){
    return this.http.post(endPoints.FEED_SEARCH,obj)
  }

  public subscriptionList(obj:any){
    return this.http.post(endPoints.SUBSCRIPTION_LIST,obj)
  }

  public getspamList(obj:any){
    return this.http.get(`${endPoints.SPAM_USER_LIST}?skip=${obj.skip}&limit=${obj.limit}&search=`);
  }
}

function handleError(response, notifiers) {
  if (response['code'] == environment.STATUS_CODE.FAILURE.CODE) {
    // window.alert(`Error : ${response['message']}`);
    notifiers.notify('error', `Error : ${response['message']}`);;
  }
  else if (response['code'] == environment.STATUS_CODE.SESSION_EXPIRE.CODE) {
    notifiers.notify('error', `Error : ${response['message']}`);
    window.location.href = window.location.origin + '/#/login'
    // window.location.hash = "";

  }
  else if (response['code'] == environment.STATUS_CODE.SUCCESS.CODE) {
    return response;
  }
  else if (response['code'] == environment.STATUS_CODE.API_NOT_FOUND.CODE) {
    return notifiers.notify('error', `Error: ${response.status} NOT FOUND`);
  }
  else if (response['code'] == environment.STATUS_CODE.DATA_NOT_FOUND.CODE) {
    // window.alert(`Error: ${response.status} NOT FOUND`);

  }
  else {
    return notifiers.notify('error', `Error: Technical Error!! Please try again after sometime.`);
    // window.alert(`Error: Technical Error!! Please try again after sometime.`);

  }
  // console.log("POST call successful value returned in body",
  //   response);

}
function encrypt(data) {
  let key = localStorage.getItem('public_key');
  var encrypt = new  JsEncryptModule.JSEncrypt({});
  encrypt.setPublicKey(key);
  let encrypted_data = encrypt.encrypt(JSON.stringify(data));
  return encrypted_data;
}
