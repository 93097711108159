<div class="row">
    <div class="col-md-12">
      <h3 class="page-title"> {{title}} </h3>
      <ul class="page-breadcrumb breadcrumb">
        <li> <i class="fa fa-home"></i> <a href=""> Home </a> <i class="fa fa-angle-right"></i>
        </li>
        <li> <a href="javascript:void(0);"> {{title}} </a> </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row search-form-default">
        <div class="col-md-12">
          <form action="#" id="search">
            <div class="search-hero">
              <input class="form-control" type="text" name="search" (keyup)="serachFeed()" [(ngModel)]="search"
                autocomplete="off" placeholder="&#61442;  Start searching for a user by Created By, Reported By">
            </div>
          </form>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th> Profile</th>
              <!-- <th> Email</th>
              <th> Name </th> -->
              <th> Created By</th>
              <!-- <th> Mobile Number</th> -->
              <!-- <th> Email Verification</th> -->
              <th> Likes </th>
              <th> DisLikes </th>
              <th> Reported By </th>
                
              <th> Created At</th>
              <!-- <th> Status </th> -->
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            <!--  | filter: search | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalRecords }-->
            <tr
              *ngFor="let data of responseData | filter: search | paginate: { itemsPerPage: itemsPerPage, currentPage: p, totalItems: totalCount}; let i = index ">
              <td>{{ itemsPerPage * (p - 1) + i+1 }} </td>
              <td>
                <div class="zoom">
                  <img
                    src="{{data.profile!='default.jpg'? IMAGE_BASE_URL+data.userId+'/'+data.profile: IMAGE_BASE_URL+'default.jpg' | lowercase}}"
                    alt="Avatar" class="avatar">
                  <br>
                  <p>{{data.username}}</p>
                </div>
  
              </td>
              <!-- <td style="text-transform: lowercase">{{data.email}}</td>
              <td style="text-transform: lowercase">{{data.fullName}}</td> -->
  
              <td>{{data.fullName}}</td>
              <td>{{data.likes}}</td>
              <td>{{data.disLikes}}</td>
              <td>{{data.reportedBy.length?data.reportedBy:'----'}}</td>

  
              <!-- <td>
                {{data.countryCode}}{{data.phone?data.country_code_text+' '+data.phone:'Null'}}</td> -->
              <!-- <td> {{data.isEmailVeried?'Verified':'Pending'}}</td> -->
              <td>{{data.created_at | date:'fullDate'}}</td>
              <!-- <td>
                <span [ngClass]="(data.isBlocked==false)?'badge badge-success':'badge badge-dark'">{{data.isBlocked ==
                  false ? 'Active':'Inactive'}}</span>
              </td> -->
              <td>
                <a [routerLink]="['/admin/feeds-management/feed-detail/',data._id]"> <i class="fa fa-eye"></i>
                </a>
                <!-- <a *ngIf="!data.isBlocked" (click)="blockUser(data._id)"><i class="fa fa-lock" aria-hidden="true"
                    style="height: 20px; width: 20px;"></i></a> -->
                <!-- <a *ngIf="data.isBlocked" (click)="unblockUser(data._id)"><i class="fa fa-unlock" aria-hidden="true"
                    style="height: 20px; width: 20px;"></i></a> -->
                <a (click)="deleteFeed(data._id)"><i class="fa fa-trash-o"></i> </a>
                <!-- <span class="label label-sm"> <a (click)="activeInactiveUser(data._id,!data.isBlocked)"
                    [title]="data.isBlocked==false ? 'Inactive User':'Active User'"></a>
                </span> -->
            </tr>
  
          </tbody>
        </table>
        <p *ngIf="!length" class="text-center" style="font-size: x-large;">No Record Found</p>
      </div>
      <p *ngIf="totalRecords==0">No results Found</p>
      <div class="margin-top-20 text-center">
        <ul class="pagination">
          <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)" maxSize="9"
            directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous" nextLabel="Next"
            screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
            screenReaderCurrentLabel="You're on page"></pagination-controls>
        </ul>
      </div>
    </div>
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white" > Loading  </p>
  </ngx-spinner>
  </div>